import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import XpresUtils from '../../shared/xpres.util';
import { WINDOW } from 'src/app/shared/services/window.provider';
import { catchError, switchMap } from 'rxjs/operators';
interface ICouponBooklet {
  name: string;
  quantity: number;
  price: number;
  expires: string;
  categoria: { nombre: string };
}
interface ICouponPurchase {
  couponBooklet: {
    id: string;
  }
}
@Injectable()
export class CuponerasService {
  backendUrl: string = null;
  constructor(private _httpClient: HttpClient, @Inject(WINDOW) private window: Window) {
    this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);
  }
  getCuponeras(): Observable<any> {
    return this._httpClient.get<Array<any>>(
      `${this.backendUrl}/cuponeras`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'body',
        responseType: 'json'
      });
  }
  getAvailableCuponeras(destination: string, isPickup: boolean = false, categoyId: string): Observable<any> {
    const params = new HttpParams()
      .set('destination', destination)
      .set('isPickup', String(isPickup))
      .set("categoryId", categoyId );
    return this._httpClient.get<Array<any>>(
      `${this.backendUrl}/cuponeras/get-coupons`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'body',
        responseType: 'json',
        params: params
      });
  }
  getHostname(): string {
    return this.window.location.protocol + '//' + this.window.location.host;
  }
  saveCouponPurchase(couponPurchase: ICouponPurchase): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      couponBooklet: couponPurchase.couponBooklet,
      backendUrl: `${this.getHostname()}`
    };
    return this._httpClient.request<Observable<HttpResponse<Object>>>('post',
      `${this.backendUrl}/cuponeras/saveAndRequestPayment`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const savedShip = res.body;
          return of(savedShip);
        })
      );
  }
  savePaymentResponse(id: string, mpParams: any): Observable<any> {
    if (id) {
      console.log(mpParams);
      const status = mpParams.status === 0 ? 'success' : mpParams.status === 1 ? 'pending' : 'failure';
      const body = mpParams.params;
      const params = new HttpParams().
      set("payment_id", mpParams.paymentId);
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this._httpClient.post(`${this.backendUrl}/cuponeras/payment/${id}/${status}`,
        body, {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const coupon = <ICouponBooklet>(res.body);
          return of(coupon);
        }),
        catchError(err => {
          return throwError(err);
        })
      );
    } else {
      return of(null);
    }
  }
}

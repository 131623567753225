import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {

  _isMobile = false;
  bsModalRef: BsModalRef;
  config: any = {};
  @ViewChild('termsTemplate') elementView: ElementRef;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _auth: AuthService,
    private modalService: BsModalService,
    private _responsiveService: ResponsiveService
  ) {
    this._unsubscribeAll = new Subject();

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = breackpoint === 'xs';
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  show(): void {
    this.bsModalRef = this.modalService.show(this.elementView, {
      class: 'mw-100' + (!this._isMobile ? ' w-75' : ''),
      backdrop: 'static',
      keyboard: false
    });
  }
}

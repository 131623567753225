import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/internal/operators';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IPage } from '../../../../shared/models/page';
import { IPickupCenter, PickupCenter } from '../service/pickup.model';

interface IReturn {
  departamentos: any[];
  centro: IPickupCenter;
  type: string;
}

@Injectable()
export class PickupCentersService {

  routeParams: any;
  pickupCenter: any;
  onPickupCentersChanged: BehaviorSubject<any>;

  filter = '';
  sortField = '';
  sortOrder = 'asc';
  page = 0;
  size = 10;

  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onPickupCentersChanged = new BehaviorSubject({});
  }

  getDepartamentos(): Observable<any> {
    return this._httpClient.get<Observable<Array<any>>>(
      `${environment.endpoint}/departamentos`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json',
        params: new HttpParams()
          .set('page', '1')
          .set('size', '10000')
      });
  }

  translate(body: any): IPickupCenter {
    return new PickupCenter({
      id: body['id'],
      codigo: body['codigo'],
      nombre: body['nombre'],
      calle: body['calle'],
      cp: body['zipCode'],
      telefono: body['phone'],
      nroPuerta: body['doorNumber'],
      adicional: body['otherInfo'],
      horario: body['openHours'],
      latitud: body['latitude'],
      longitud: body['longitude'],
      localidad: body['localidad']
    });
  }

  translateForDB(centro: IPickupCenter): any {
    return {
      id: centro.id,
      codigo: centro.codigo,
      nombre: centro.nombre,
      calle: centro.calle,
      doorNumber: centro.nroPuerta,
      otherInfo: centro.adicional,
      latitude: centro.latitud,
      longitude: centro.longitud,
      openHours: centro.horario,
      localidad: {
        id: centro.localidad.id
      }
    };
  }

  getPickupCenter(id: string): Observable<any> {
    return this._httpClient.get<Observable<any>>(
      `${environment.endpoint}/centros/${id}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      }).pipe(
        map(res => {
          const body = res['body'];
          return this.translate(body);
        })
      );
  }

  getPage(filter = this.filter, sortField = this.sortField, sortOrder = this.sortOrder,
    pageNumber = this.page, pageSize = this.size, departamentoId = null): Observable<IPage> {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let params = new HttpParams()
      .set('filter', filter)
      .set('sort[departamento]', '1').set('sort[localidad]', '1').set('sort[calle]', '1')
      .set('page', (pageNumber).toString())
      .set('size', pageSize.toString());

    if (departamentoId !== null) {
      params = new HttpParams()
        .set('filter', filter)
        .set('sort[departamento]', '1').set('sort[localidad]', '1').set('sort[calle]', '1')
        .set('page', (pageNumber).toString())
        .set('size', pageSize.toString())
        .set('where[localidad][departamento][id]', departamentoId);
    }

    return this._httpClient.get(`${environment.endpoint}/centros`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      })
      .pipe(
        map(res => {
          const count = parseInt(res.headers.get('Xpres-Count-Items'), 10);
          const rows = <any[]>(res['body']);

          this.filter = filter;
          this.sortField = sortField;
          this.sortOrder = sortOrder;
          this.page = pageNumber;
          this.size = pageSize;

          return <IPage>{
            totalCount: count,
            rows: rows.map(item => this.translate(item))
          };
        })
      );
  }


}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/internal/operators';
import { environment } from '../../../environments/environment';
import XpresUtils from '../../shared/xpres.util';

interface ITarifasReturn {
  tarifas: any[];
  tiempos: any[];
  impuestos: any[];
}

@Injectable()
export class TarifasService {

  backendUrl: string = null;
  constructor(private _httpClient: HttpClient) {
    this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);
  }

  getData(): Observable<ITarifasReturn> {
    const observers: Observable<any>[] = [
      this.getTarifas(),
      this.getTiempos(),
      this.getImpuestos()
    ];

    return forkJoin(observers)
      .pipe(
        map(results => ({
          tarifas: (<any[]>(results[0].body)),
          // tiempos: (<any[]>(results[1].body)),
          // impuestos: (<any[]>(results[2].body))
          // tarifas: (<any[]>(results[0])),
          tiempos: (<any[]>(results[1])),
          impuestos: (<any[]>(results[2]))
        }),
          catchError(err => throwError(err))
        ));
  }

  getTarifas(): Observable<any> {
    return this._httpClient.get<Observable<Array<any>>>(
      `${this.backendUrl}/tarifas`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  obtenerTarifa(serviceId: string, categoryId: string, recipientDeptId: string): Observable<any> {
    return this._httpClient.get<Observable<Array<any>>>(
      `${this.backendUrl}/tarifas/obtener?tipo=${serviceId}&categoria=${categoryId}&destino=${recipientDeptId}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      }).pipe(
          map(results => results.body,
          catchError(err => throwError(err))
        ));
  }

  getTiempos(): Observable<any> {
    const data = [];

    data.push(this.buildRecordTiempo('Montevideo', 'Montevideo', '24 hs'));
    data.push(this.buildRecordTiempo('Montevideo', 'Interior', '24/72 hs'));
    data.push(this.buildRecordTiempo('Interior', 'Montevideo', '72 hs'));
    data.push(this.buildRecordTiempo('Interior', 'Interior', '96 hs'));

    return of(data);
  }

  getImpuestos(): Observable<any> {
    return of([{
      percent: 22,
      name: 'IVA'
    }, {
      percent: 10,
      name: 'TP'
    }]);
  }

  buildRecordTiempo(origen: string, destino: string, tiempo: string) {
    return { origen, destino, tiempo };
  }
  buildRecordTarifa(servicio: string, destino: string, tipo: string, importe: number): any {
    return { servicio, destino, tipo, importe };
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { IUser, User } from '../user.model';
import { UsuarioService } from '../user.service';
import { NotificationService } from './../../../shared/services/notification.service';
import { AddressesComponent } from './../addresses/addresses.component';

declare var $: any;

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { 'passwordsNotMatching': true };
};

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  const value: string = control.value || '';

  if (!value) {
    return null;
  }

  const upperCaseCharacters = /[A-Z]+/g;
  if (upperCaseCharacters.test(value) === false) {
    return { passwordStrength: `La contraseña debe contener alguna letra mayúscula.` };
  }

  const lowerCaseCharacters = /[a-z]+/g;
  if (lowerCaseCharacters.test(value) === false) {
    return { passwordStrength: `La contraseña debe contener alguna letra minúscula.` };
  }


  const numberCharacters = /[0-9]+/g;
  if (numberCharacters.test(value) === false) {
    return { passwordStrength: `La contraseña debe contener algún número.` };
  }

  const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (specialCharacters.test(value) === false) {
    return { passwordStrength: `La contraseña debe contener algún caracter especial.` };
  }

  return null;
}



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  data: IUser;
  form: FormGroup;
  formPassword: FormGroup;

  departamentos$: Observable<Array<any>>;
  localidades$: Observable<Array<any>>;
  tiposFiscales$: Observable<Array<any>>;

  @ViewChild('addresses') addresses: AddressesComponent;

  private _unsubscribeAll: Subject<any>;

  constructor(private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _usuarioService: UsuarioService,
    private _notificationService: NotificationService) {

    this.data = new User();
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._buildForms();

    this.departamentos$ = this._usuarioService.getDepartamentos();
    this.localidades$ = this._usuarioService.getLocalidades('-1');
    this.tiposFiscales$ = this._usuarioService.getTiposFiscales();

    this._auth.currentUser
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(user => {
        this.data = user !== null ? user : new User();
        this._loadForm();
        this.addresses.userId = this.data['id'] || '';
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  _buildForms(): void {
    this.form = this._formBuilder.group({
      id: [''],
      firstName: [this.data.firstName, Validators.required],
      lastName: [this.data.lastName],
      email: [this.data.email, [Validators.required, Validators.email]],
      phone: [this.data.phoneNumber, Validators.required],
      rut: [this.data.documentNumber, Validators.required],
      fiscalType: [null, Validators.required],
      street: [this.data.street],
      nroPuerta: [this.data.doorNumber],
      additional: [this.data.additional],
      state: [null],
      city: [null]
    });

    this.formPassword = this._formBuilder.group({
      currentPassword: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8), PasswordStrengthValidator]],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
    });
  }

  formIsInvalid(name): boolean {
    if (this.form.controls && this.form.controls[name]) {
      if (this.form.controls[name]['errors'] && !this.form.controls[name]['untouched']) {
        return true;
      }
    }
    return false;
  }

  formHasError(error: string, fieldName: string): boolean {
    if (this.form.controls && this.form.controls[fieldName]) {
      if (this.form.controls[fieldName].hasError(error) && !this.form.controls[fieldName].untouched) {
        return true;
      }
    }
    return false;
  }

  passFormIsInvalid(name): boolean {
    if (this.formPassword.controls && this.formPassword.controls[name]) {
      if (this.formPassword.controls[name]['errors'] && !this.formPassword.controls[name]['untouched']) {
        return true;
      }
    }
    return false;
  }

  passFormHasError(error: string, fieldName: string): boolean {
    if (this.formPassword.controls && this.formPassword.controls[fieldName]) {
      if (this.formPassword.controls[fieldName].hasError(error) && !this.formPassword.controls[fieldName].untouched) {
        return true;
      }
    }
    return false;
  }

  _loadForm(): void {
    const f = this.form.controls;

    f['id'].setValue(this.data.id);
    f['firstName'].setValue(this.data.firstName);
    f['lastName'].setValue(this.data.lastName);
    f['email'].setValue(this.data.email);
    f['phone'].setValue(this.data.phoneNumber);
    f['street'].setValue(this.data.street);
    f['nroPuerta'].setValue(this.data.doorNumber);
    f['additional'].setValue(this.data.additional);
    f['rut'].setValue(this.data.documentNumber);

    if (this.data['state']) {
      this.localidades$ = this._usuarioService.getLocalidades(this.data.state.id);

      this.localidades$.subscribe(() => {
        this.form.get('state').setValue(this.data.state.id);
        if (this.data['city']) {
          this.form.get('city').setValue(this.data.city.id);
        }
      });
    }

    if (this.data['tipoFiscal']) {
      this.tiposFiscales$.subscribe(() => {
        this.form.get('fiscalType').setValue(this.data.tipoFiscal.id);
      });
    }
  }

  onChangeDepartamento(depId): void {
    this.form.controls['city'].reset();
    this.localidades$ = this._usuarioService.getLocalidades(depId);
  }

  saveUser(): void {
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].markAsTouched();
        this.form.controls[key].updateValueAndValidity();
      });
      return;
    }

    const user: IUser = new User({
      id: this.form.get('id').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      email: this.form.get('email').value,
      street: this.form.get('street').value,
      doorNumber: this.form.get('nroPuerta').value,
      additional: this.form.get('additional').value,
      phoneNumber: this.form.get('phone').value,
      documentNumber: this.form.get('rut').value,
      city: (this.form.get('city').value ? { id: this.form.get('city').value } : null),
      tipoFiscal: (this.form.get('fiscalType').value ? { id: this.form.get('fiscalType').value } : null)
    });

    this._usuarioService.updateUser(user)
      .subscribe(() => {
        this._notificationService.showSuccess('Datos actualizados!');
        this._auth.refreshUser()
          .subscribe(savedUser => {
            this.data = savedUser;
            this._loadForm();
          });
      });
  }

  changePassword(): void {
    if (!this.formPassword.valid) {
      Object.keys(this.formPassword.controls).forEach(key => {
        this.formPassword.controls[key].markAsTouched();
        this.formPassword.controls[key].updateValueAndValidity();
      });
      return;
    }

    this._usuarioService.changePassword(
      this.form.get('id').value,
      this.formPassword.get('currentPassword').value,
      this.formPassword.get('password').value)
      .subscribe(() => {
        this._notificationService.showSuccess('Contraseña actualizada!');
      });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { MyCouponBookletService } from '../my-coupon-booklets.service';
declare var $: any;
@Component({
  selector: 'app-list-coupon-booklets',
  templateUrl: './list-coupon-booklets.component.html',
  styleUrls: ['./list-coupon-booklets.component.scss']
})
export class ListCouponBookletsComponent implements OnInit, OnDestroy {
  _isMobile = false;
  myCouponBooklets$: Observable<any>;
  size: number;
  p: number;
  total: number;
  loading: boolean;
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _myCouponBookletService: MyCouponBookletService,
    private _responsiveService: ResponsiveService,
  ) {
    this.p = 1;
    this.size = 10;
    this._unsubscribeAll = new Subject();
    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = (breackpoint === 'xs');
      });
    this._responsiveService.check();
  }
  ngOnInit() {
    this.getPage(1);
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  isExpired(expirationDate: Date | null): boolean {
    // If expirationDate is null, it's not expired.
    if (!expirationDate) {
      return false;
    }
    
    // Compare the expiration date with the current date.
    const currentDate = new Date();
    return new Date(expirationDate) < currentDate;
  }
  getPage(page: number) {
    this.loading = true;
    this.myCouponBooklets$ = this._myCouponBookletService.getMyCouponBooklets(page, this.size)
      .pipe(
        tap(res => {
          this.total = res.total;
          this.p = page;
          this.loading = false;
        }),
        map(res => res.items)
      );
      this.myCouponBooklets$.subscribe();
  }
}


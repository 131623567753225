import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/internal/operators';
import { Centro } from '../../shared/models/centro';
import { environment } from '../../../environments/environment';
import XpresUtils from '../../shared/xpres.util';

interface IXPresReturn {
  departamentos: any[];
  centros: any[];
  montevideo: any;
}

@Injectable()
export class PuntosService {

  backendUrl: string = null;
  constructor(private _httpClient: HttpClient) {
    this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);
  }

  getData(): Observable<IXPresReturn> {
    const observers: Observable<any>[] = [
      this.getDepartamentosPickup(),
      this.getCentros(),
      this.getDepartamentoMvd()
    ];

    return forkJoin(observers)
      .pipe(
        map(results => ({
          departamentos: (<any[]>(results[0].body)),
          centros: (<any[]>(results[1].body)),
          montevideo: results[2].body
        }),
          catchError(err => throwError(err))
        ));
  }

  getCentrosPorDepartamento(depId: string): Observable<any> {
    const observers: Observable<any>[] = [
      this.getCentros(depId),
      this.getDepartamento(depId)
    ];

    return forkJoin(observers)
      .pipe(
        map(results => ({
          centros: (<any[]>(results[0].body)),
          departamento: results[1].body
        }),
          catchError(err => throwError(err))
        ));
  }

  getDepartamentos(): Observable<any> {
    return this._httpClient.get<Observable<Array<any>>>(
      `${this.backendUrl}/departamentos?sort[nombre]=1&size=100`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  getDepartamentosPickup(): Observable<any> {
    return this._httpClient.get<Observable<Array<any>>>(
      `${this.backendUrl}/departamentos?where[pickup]=1&sort[nombre]=1&size=100`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  getCentros(departamento: string = 'Montevideo'): Observable<any> {
    let deptoParam = '';

    if (XpresUtils.isValidUuid(departamento)) {
      deptoParam = `&departamentoId=${departamento}`;
    } else {
      deptoParam = `&departamento=${departamento}`;
    }

    return this._httpClient.get<Observable<Array<Centro>>>(
      `${this.backendUrl}/centros?page=1&size=1000${deptoParam}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  getDepartamentoMvd(): Observable<any> {
    return this._httpClient.get<Observable<any>>(
      `${this.backendUrl}/departamentos/mvd`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  getDepartamento(depId: string): Observable<any> {
    return this._httpClient.get<Observable<any>>(
      `${this.backendUrl}/departamentos/${depId}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }
}

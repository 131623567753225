import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/shared/services/contact.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  form: FormGroup;
  constructor(
    private builder: FormBuilder,
    private service: ContactService,
    private notifications: NotificationService) { }

  ngOnInit() {
    this.form = this.builder.group({
      motivo: [null, Validators.required],
      nombre: [null, Validators.required],
      apellido: [null, Validators.required],
      email: [null, [Validators.email, Validators.required]],
      asunto: [null, Validators.required],
      mensaje: [null, Validators.required],
      telefono: [null]
    });
  }

  submit() {
    const msgSucc = "Su consulta fue enviada correctamente, un representante especializado de atención al cliente le dará respuesta a la brevedad.\n\nHorario de atención: lunes a viernes de 10.00 a 18.00 horas\n\nMuchas gracias."
    this.service.contactUs(this.form.value).subscribe(
      r => {
        if (r.success) {
          this.notifications.showSuccess(msgSucc, 'Mensaje enviado', 5000);
        } else {
          this.notifications.showError(r.message, 'Error enviando mensaje');
        }
      }, e => this.notifications.showError('El mensaje no pude ser enviado')
    );
  }

}
import { NgModule } from '@angular/core';
import { disableDebugTools } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { PageLayoutComponent } from './components/layout/page-layout/page-layout.component';
import { Page404Component } from './components/page404/page404.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { Dialogs } from './shared/dialogs.enum';
import { CuponerasComponent } from './components/cuponeras/cuponeras.component';


const routes: Routes = [
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          section: 'welcome-page-xpres'
        }
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          section: 'welcome-page-xpres'
        }
      },
      {
        path: 'home/:id/:status',
        component: HomeComponent
      },
      {
        path: 'verify/:verificationId',
        component: HomeComponent
      },
      {
        path: 'forgot/:resetToken',
        component: HomeComponent
      },
      {
        path: 'como-funciona',
        component: HomeComponent,
        data: {
          section: 'como-funciona'
        }
      },
      {
        path: 'section-puntos-xpres',
        component: HomeComponent,
        data: {
          section: 'section-puntos-xpres'
        }
      },
      {
        path: 'section-servicios',
        component: HomeComponent,
        data: {
          section: 'section-servicios'
        }
      },
      {
        path: 'section-contact',
        component: HomeComponent,
        data: {
          section: 'contact-form'
        }
      },
      {
        path: 'crear-envio',
        component: HomeComponent,
        data: {
          dialog: Dialogs.CREAR_ENVIO
        }
      },
      {
        path: 'cuponeras',
        component: CuponerasComponent,
      },
      {
        path: 'cuponeras/:id/:status',
        component: CuponerasComponent,
      },
      {
        path: 'faq',
        component: FaqComponent
      }
    ]

  },

  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListaLocalidadesComponent } from './components/admin/localidades/lista-localidades/lista-localidades.component';
import { LocalidadesComponent } from './components/admin/localidades/localidades.component';
import { LocalidadesService } from './components/admin/localidades/service/localidades.service';
import { ListaCentrosComponent } from './components/admin/pickup-centers/lista-centros/lista-centros.component';
import { PickupCentersComponent } from './components/admin/pickup-centers/pickup-centers.component';
import { PickupCentersService } from './components/admin/pickup-centers/service/pickup-centers.service';
import { AsistenteComponent } from './components/asistente/asistente.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LoginService } from './components/auth/login/login.service';
import { EnvioComponent } from './components/envio/envio.component';
import { EnvioService } from './components/envio/envio.service';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { ListaPaquetesComponent } from './components/mis-paquetes/lista-paquetes/lista-paquetes.component';
import { MisPaquetesComponent } from './components/mis-paquetes/mis-paquetes.component';
import { MisPaquetesService } from './components/mis-paquetes/mis-paquetes.service';
import { Page404Component } from './components/page404/page404.component';
import { PuntosComponent } from './components/puntos/puntos.component';
import { PuntosService } from './components/puntos/puntos.service';
import { TarifasComponent } from './components/tarifas/tarifas.component';
import { TarifasService } from './components/tarifas/tarifas.service';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { TrackingHistoryComponent } from './components/tracking-history/tracking-history.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { AddressService } from './components/user/addresses/address.service';
import { AddressesComponent } from './components/user/addresses/addresses.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { UsuarioService } from './components/user/user.service';
import { UserComponent } from './components/user/user/user.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { AuthService } from './shared/auth/auth.service';
import { GlobalErrorHandler } from './shared/globalErrorHandler';
import { BsEventsService } from './shared/services/bs-events.service';
import { ErrorService } from './shared/services/error.service';
import { LoggingService } from './shared/services/logging.service';
import { ModalDataService } from './shared/services/modal-data.service';
import { NotificationService } from './shared/services/notification.service';
import { ResponsiveService } from './shared/services/responsive.service';
import { WINDOW_PROVIDERS } from './shared/services/window.provider';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RECAPTCHA_SETTINGS, RECAPTCHA_LANGUAGE, RecaptchaSettings } from 'ng-recaptcha';
import { FaqComponent } from './components/faq/faq.component';
import { CuponerasComponent } from './components/cuponeras/cuponeras.component';
import { PageLayoutComponent } from './components/layout/page-layout/page-layout.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactFloatingButtonComponent } from './components/contact/contact-floating-button/contact-floating-button.component';
import { ScrollToTopButtonComponent } from './components/scroll-to-top-button/scroll-to-top-button.component';
import { NavLinkDirective } from './directives/nav-link.directive';
import { CuponerasService } from './components/cuponeras/cuponeras.service';
import { MyCouponBookletService } from './components/my-coupon-booklets/my-coupon-booklets.service';
import { MyCouponBookletComponent } from './components/my-coupon-booklets/my-coupon-booklets.component';
import { ListCouponBookletsComponent } from './components/my-coupon-booklets/list-coupon-booklets/list-coupon-booklets.component';
import { StatusTranslatePipe } from './components/my-coupon-booklets/statusTranslate';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Page404Component,
    HeaderComponent,
    FooterComponent,
    TrackingComponent,
    UserComponent,
    LoginComponent,
    PuntosComponent,
    TarifasComponent,
    TrackingHistoryComponent,
    EnvioComponent,
    AsistenteComponent,
    AddressesComponent,
    ProfileComponent,
    TermsAndConditionsComponent,
    MisPaquetesComponent,
    MyCouponBookletComponent,
    ListaPaquetesComponent,
    ListCouponBookletsComponent,
    LocalidadesComponent,
    ListaLocalidadesComponent,
    PickupCentersComponent,
    ListaCentrosComponent,
    ResetPasswordComponent,
    FaqComponent,
    CuponerasComponent,
    PageLayoutComponent,
    ContactComponent,
    ContactFloatingButtonComponent,
    ScrollToTopButtonComponent,
    NavLinkDirective,
    StatusTranslatePipe
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    LayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,

    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),

    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),
    AgmSnazzyInfoWindowModule,
    NgSelectModule,

    AppRoutingModule,

    ModalModule.forRoot(),
    NgxPaginationModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    AuthService,
    WINDOW_PROVIDERS,
    AuthGuard,

    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },

    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },

    ErrorService,
    LoggingService,
    NotificationService,
    ResponsiveService,
    BsModalRef,
    BsEventsService,

    PuntosService,
    CuponerasService,
    TarifasService,
    EnvioService,
    LoginService,
    UsuarioService,
    AddressService,
    ModalDataService,
    MisPaquetesService,
    MyCouponBookletService,
    LocalidadesService,
    PickupCentersService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: `${environment.reCaptchaKey}` } as RecaptchaSettings
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'sp'
    }
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

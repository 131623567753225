import { Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { UsuarioService } from '../user.service';

declare var $: any;

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { 'passwordsNotMatching': true };
};

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  formPassword: FormGroup;

  _isMobile = false;
  bsModalRef: BsModalRef;
  @ViewChild('resetPassTemplate') elementView: ElementRef;
  private _unsubscribeAll: Subject<any>;

  @Input() token: any;
  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private _auth: AuthService,
    private _userService: UsuarioService,
    private modalService: BsModalService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private _responsiveService: ResponsiveService,
    private _notificationService: NotificationService
  ) {
    this._unsubscribeAll = new Subject();

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = breackpoint === 'xs';
      });

  }

  ngOnInit(): void {
    this.formPassword = this._formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(6)]],
      passwordConfirm: [null, [Validators.required, confirmPasswordValidator]]
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  passFormIsInvalid(name): boolean {
    if (this.formPassword.controls && this.formPassword.controls[name]) {
      if (this.formPassword.controls[name]['errors'] && !this.formPassword.controls[name]['untouched']) {
        return true;
      }
    }
    return false;
  }

  passFormHasError(error: string, fieldName: string): boolean {
    if (this.formPassword.controls && this.formPassword.controls[fieldName]) {
      if (this.formPassword.controls[fieldName].hasError(error) && !this.formPassword.controls[fieldName].untouched) {
        return true;
      }
    }
    return false;
  }

  show(): void {
    this.open.emit(null);
    this.modalService.onHidden.subscribe((reason: string) => {
      this.goHome();
    });

    this.bsModalRef = this.modalService.show(this.elementView, {
      class: 'mw-100' + (!this._isMobile ? ' w-25' : ''),
      backdrop: 'static',
      keyboard: false
    });
  }

  changePassword(): void {
    if (!this.formPassword.valid) {
      Object.keys(this.formPassword.controls).forEach(key => {
        this.formPassword.controls[key].markAsTouched();
        this.formPassword.controls[key].updateValueAndValidity();
      });
      return;
    }

    const password = this.formPassword.get('password').value;

    this._userService.resetPassword(this.token, password)
      .subscribe(user => {
        this.bsModalRef.hide();
        this._notificationService.showInfo('Contraseña cambiada exitosamente.')
          .then(() => {
            this.goHome();
          });
      }, err => {
        this._notificationService.showError(err.error.error.message)
          .then(() => {
            this.goHome();
          });
      });
  }

  goHome() {
    this.router.navigate(['home'], { queryParams: { ts: new Date().getTime().toString(36) } });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'statusTranslate'
})
export class StatusTranslatePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'pending':
        return 'Pendiente';
      case 'success':
        return 'Aprobado';
      case "failure":
        return "Rechazado";
      default:
        return value;
    }
  }
}

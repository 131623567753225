import { Directive, ElementRef, HostListener } from '@angular/core';
import { HeaderMenuService } from '../shared/services/header-menu.service';

/**
 * navlink directive that handles teh click event generically and hides the
 * mobile menu.
 */
@Directive({
  selector: '[appNavLink]'
})
export class NavLinkDirective {

  constructor(private el: ElementRef, private header: HeaderMenuService) { 

  }

  @HostListener('click')
  onClick(): void {
    this.header.setMobileMenuDisplayed(false);
  }

}

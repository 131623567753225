import { ResetPasswordComponent } from './../user/reset-password/reset-password.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ScrollService } from 'src/app/shared/services/scroll.service';
import { HeaderMenuService } from 'src/app/shared/services/header-menu.service';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { AsistenteService } from '../asistente/asistente.service';
import { Dialogs } from 'src/app/shared/dialogs.enum';
import { HomeService } from './home.service';
import { UserComponent } from '../user/user/user.component';
import { MisPaquetesComponent } from '../mis-paquetes/mis-paquetes.component';
import { LocalidadesComponent } from '../admin/localidades/localidades.component';
import { MyCouponBookletComponent } from '../my-coupon-booklets/my-coupon-booklets.component';

//TODO LIMPIAR CODIGO
// import { PickupCentersComponent } from '../admin/pickup-centers/pickup-centers.component';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  currentUser: any = null;
  resetToken: string = null;

  private _unsubscribeAll: Subject<any> = new Subject();
  private verificationId: string = null;

  @ViewChild(ResetPasswordComponent) resetPassword: ResetPasswordComponent;
  @ViewChild(UserComponent) user: UserComponent;
  @ViewChild(MisPaquetesComponent) misPaquetes: MisPaquetesComponent;
  @ViewChild(MyCouponBookletComponent) myCouponBooklets: MyCouponBookletComponent;
  @ViewChild(LocalidadesComponent) localidades: LocalidadesComponent;
  // @ViewChild(PickupCentersComponent) centros: PickupCentersComponent;

  mobileMenuDisplayed = false;
  isMobile: boolean;
  displayCodEnvio = false;
  displayTipoPac = false;
  displayTipoServ = false;
  displayEntidadF = false;
  displayTiposInfo = false;
  paramEnvioId: string = null;

  constructor(
    private _auth: AuthService,
    private router: Router,
    private _notificationService: NotificationService,
    private route: ActivatedRoute,
    private _scroll: ScrollService,
    private _menu: HeaderMenuService,
    private _responsive: ResponsiveService,
    private _asistente: AsistenteService,
    private service: HomeService) {
  }

  ngOnInit() {
    const qs: string[] = this.route.snapshot.queryParamMap.keys;
    this.verificationId = this.route.snapshot.params.verificationId;
    this.resetToken = this.route.snapshot.params.resetToken;
    const routeData = this.route.snapshot.data;

    this._auth.currentUser
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(user => {
        this.currentUser = user;
      });

    if (this._auth.isLoggedIn()) {
      if (!(this.currentUser || {})['email']) {
        this._auth.refreshUser().subscribe();
      }
    }


    this._responsive.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breakpoint => {
        this.isMobile = breakpoint !== 'xl' && breakpoint !== 'lg';
      });

    if (this.verificationId) {
      this._auth.verify(this.verificationId)
        .subscribe(user => {
          this._notificationService.showInfo('Gracias por verificar su cuenta. Ya puede ingresar utilizando su usuario y contraseña.')
            .then(() => this.goHome());
        }, err => {
          this._notificationService.showError(err.error.error.message)
            .then(() => this.goHome());
        });
    } else if (this.resetToken) {
      this._auth.checkResetToken(this.resetToken)
        .subscribe(info => {
          this.resetPassword.show();
        }, err => {
          this._notificationService.showError(err.error.error.message)
            .then(() => this.goHome());
        });
    }

    if (routeData.section !== undefined) {
      this._scroll.goToSection(routeData.section);
      if (routeData.section === 'welcome-page-xpres') {
        this.service.focusSearchInput();
      }
    }
    if (routeData.dialog !== undefined) {
      switch (routeData.dialog) {
        case Dialogs.CREAR_ENVIO: {
          this.crearEnvio();
          break;
        }
        default: break;
      }
    }

    this._menu.getMobileMenuDisplayed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(displayed => {
        console.log('[home] menu displayed: ', displayed);
        this.mobileMenuDisplayed = displayed;
      });
    this._responsive.check();

    this._auth.observeIsUserLoggedIn()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(l => {
        if (l) {
          console.log('user logged in');
        } else {
          console.log('user logged out');
        }
      });

  }

  goHome() {
    this.router.navigate(['home'], { queryParams: { ts: new Date().getTime().toString(36) } });
  }

  ngOnDestroy(): void {
    console.log('destroy home');
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  isAuthenticated(): boolean {
    return this.currentUser !== null;
  }

  contactUs(e: any): void {
    e.preventDefault();

    const position = $('#section-contact').offset().top;

    $('body, html').animate({
      scrollTop: position
    } /* speed */);
  }

  contMotivo2(evt) {
    const sel = evt.target;
    if (sel.value !== '') {

      if (sel.value === '1' || sel.value === '2') {
        if (sel.value === '1') { // paquetería
          this.displayTipoPac = true;
          this.displayCodEnvio = true;
        } else {
          this.displayTipoPac = false;

          if (sel.value === '2') { // postal
            this.displayTipoServ = true;
            this.displayEntidadF = true;
            this.displayCodEnvio = true;

          } else {
            this.displayTipoServ = false;
            this.displayEntidadF = false;

          }
        }
      } else {
        this.displayCodEnvio = false;
      }

    }
  }

  contTipopac2(evt) {
    const sel = evt.target;
    if (sel.value !== '') {
      if (sel.value === '1') { // Mercadolibre
        this.displayTiposInfo = true;
      } else {
        this.displayTiposInfo = false;
      }
    } else {
      this.displayTiposInfo = false;
    }

  }

  crearEnvio(): void {
    //TODO agrego este setTimeOut
    setTimeout(() => {
      this._asistente.show();
    });

  }

  showProfile(): void {
    if (this._auth.isLoggedIn()) {
      this.user.show();
    }
  }

  showPackages(): void {
    if (this._auth.isLoggedIn()) {
      this.misPaquetes.show();
    }
  }

  showAdminLocalidades(): void {
    if (this._auth.isLoggedIn()) {
      this.localidades.show();
    }
  }

  showMyCouponBooklets(): void {
    if (this._auth.isLoggedIn()) {
      this.myCouponBooklets.show();
    }
  }

  //TODO esta no se usaria
  /*
    showAdminPuntos(): void {
      if (this._auth.isLoggedIn()) {
        this.centros.show();
      }
    }
  */
  onOpenEnvio(event: any): void {
    this.paramEnvioId = null;
  }

  onOpenMisPaquetes(event: any): void { }

  onOpenMyCouponBooklets(event: any): void { }

  onOpenLocalidades(event: any): void { }

  onOpenCentros(event: any): void { }

}

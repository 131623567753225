import { ILocalidad, Localidad } from './../service/localidad.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil, tap, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { LocalidadesService } from './../service/localidades.service';
import { UsuarioService } from 'src/app/components/user/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

declare var $: any;

@Component({
  selector: 'app-lista-localidades',
  templateUrl: './lista-localidades.component.html',
  styleUrls: ['./lista-localidades.component.scss']
})
export class ListaLocalidadesComponent implements OnInit, OnDestroy {

  _isMobile = false;
  _isNew = false;

  localidades$: Observable<any>;
  departamentos$: Observable<Array<any>>;

  filter: string;
  size: number;
  p: number;
  total: number;
  loading: boolean;

  searchForm: FormGroup;
  form: FormGroup;

  localidad:ILocalidad = null;

  private _unsubscribeAll: Subject<any>;
  private searchTextChanged = new Subject<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private _localidadesService: LocalidadesService,
    private _usuarioService: UsuarioService,
    private _responsiveService: ResponsiveService,
    private _notificationService: NotificationService
  ) {
    this.filter = '';
    this.p = 1;
    this.size = 10;
    this._unsubscribeAll = new Subject();

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = (breackpoint === 'xs');
      });
    this._responsiveService.check();

    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap(search => {
          this.filter = search;
          return of(null);
        })
      )
      .subscribe(() => {
        console.log('buscar ' + this.filter);
        this.getPage(1);
      });
  }

  ngOnInit() {
    this.departamentos$ = this._usuarioService.getDepartamentos();

    this.searchForm = this._formBuilder.group({
      search: [''],
      state: [null]
    });

    this.form = this._formBuilder.group({
      id: [null],
      codigo: [{value: null, disabled: true}],
      nombre: [null, Validators.required],
      departamento: [null, Validators.required]
    });

    this.getPage(1);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getPage(page: number) {
    this.loading = true;
    const departamentoId = this.searchForm.get('state').value;
    this.localidades$ = this._localidadesService.getPage(this.filter, 'departamento', 'asc', page, this.size, departamentoId)
      .pipe(
        tap(res => {
          this.total = res.totalCount;
          this.p = page;
          this.loading = false;
        }),
        map(res => {
          console.log(res);
          return res['rows'];
        })
      );
  }

  formIsInvalid(name): boolean {
    if (this.form.controls && this.form.controls[name]) {
      if (this.form.controls[name]['errors'] && !this.form.controls[name]['untouched']) {
        return true;
      }
    }
    return false;
  }

  formHasError(error: string, fieldName: string): boolean {
    if (this.form.controls && this.form.controls[fieldName]) {
      if (this.form.controls[fieldName].hasError(error) && !this.form.controls[fieldName].untouched) {
        return true;
      }
    }
    return false;
  }

  showDetail(itemId: string): void {
    $('.slider').animate({ 'margin-right': '0' }, 200);
    this._isNew = false;

    this.form.controls['codigo'].disable();
    this._localidadesService.getLocalidad(itemId)
      .subscribe(resp => {
        this.localidad = resp.body;
        this.form.controls['id'].setValue(this.localidad.id);
        this.form.controls['codigo'].setValue(this.localidad.codigo);
        this.form.controls['nombre'].setValue(this.localidad.nombre);
        this.form.controls['departamento'].setValue(this.localidad.departamento.id);
      });
  }

  hideDetail(): void {
    $('.slider').animate({ 'margin-right': '-100%' }, 200);
    this.localidad = new Localidad();
    this._isNew = false;
  }

  addNew(): void {
    $('.slider').animate({ 'margin-right': '0' }, 200);

    this._isNew = true;
    this.localidad = new Localidad();
    this.form.controls['codigo'].enable();

    this.form.controls['id'].reset();
    this.form.controls['codigo'].reset();
    this.form.controls['nombre'].reset();
    this.form.controls['departamento'].reset();
  }

  onSearchType(value: string): void {
    this.searchTextChanged.next(value);
  }

  onChangeDepartamento(evt): void {
    console.log(this.searchForm.get('state').value);
    this.getPage(1);
  }

  saveLocalidad() {
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].markAsTouched();
        this.form.controls[key].updateValueAndValidity();
      });
      return;
    }

    this.localidad = new Localidad({
      id: this.form.get('id').value,
      codigo: this.form.get('codigo').value,
      nombre: this.form.get('nombre').value,
      departamento: {
        id: this.form.get('departamento').value
      }
    });

    if (!this._isNew) {
      this._localidadesService.guardarLocalidad(this.localidad)
        .subscribe(() => {
          this._notificationService.showInfo('Cambios almacenados exitosamente.', 'Modificar localidad');
          this.hideDetail();
          this.getPage(this.p);
        });
    } else {
      this._localidadesService.crearLocalidad(this.localidad)
        .subscribe(() => {
          this._notificationService.showInfo('Localidad creada exitosamente.', 'Agregar localidad');
          this.hideDetail();
          this.getPage(this.p);
        });
    }
  }

  deleteLocalidad() {
    this._localidadesService.eliminarLocalidad(this.localidad)
      .subscribe(() => {
        this._notificationService.showInfo('Localidad eliminada.', 'Eliminar localidad');
        this.hideDetail();
        this.getPage(this.p);
      });
  }
}

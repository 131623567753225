import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  focusSearchSubject: Subject<void> = new Subject();

  constructor() { }

  focusSearchInput(): void {
    this.focusSearchSubject.next();
  }

  observeFocusSearchInput(): Observable<void> {
    return this.focusSearchSubject.asObservable();
  }
}

import { Injectable } from '@angular/core';
// import { SlackService } from './slack.service';

@Injectable()
export class LoggingService {

  constructor(/*private slackService: SlackService*/) { }

  logError(message: string, stack: string): void {
    // this.slackService.postErrorOnSlack(message, stack);
    console.log('--------', message, stack);
  }
}

export interface IUser {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  state: {
    id: string;
    description?: string;
  } | null;
  city: {
    id: string;
    description?: string;
  } | null;
  street?: string;
  doorNumber?: string;
  additional?: string;
  documentNumber?: string;
  tipoFiscal?: { id: string; description?: string; };
  password?: string;
  newPassword?: string;
}

export class User implements IUser {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  state: { id: string; description?: string; };
  city: { id: string; description?: string; };
  street?: string;
  doorNumber?: string;
  additional?: string;
  documentNumber?: string;
  tipoFiscal?: { id: string; description?: string; };
  password?: string;
  newPassword?: string;
  isAdmin?: boolean;

  constructor(user: any = {}) {
    this.id = user ? user.id : null;
    this.firstName = user ? user.firstName : null;
    this.lastName = user ? user.lastName : null;
    this.email = user ? user.email : null;
    this.phoneNumber = user ? user.phoneNumber : null;
    this.state = user ? user.state : null;
    this.city = user ? user.city : null;
    this.street = user ? user.street : null;
    this.doorNumber = user ? user.doorNumber : null;
    this.additional = user ? user.additional : null;
    this.documentNumber = user ? user.documentNumber : null;
    this.tipoFiscal = user ? user.tipoFiscal : null;
    this.password = user ? user.password : null;
    this.newPassword = user ? user.newPassword : null;
    this.isAdmin = user ? user.isAdmin === true : false;
  }
}

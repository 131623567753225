import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';

import { first, tap, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private auth: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.isAuthenticated()
      .pipe(
        first(),
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['home'], { queryParams: { returnUrl: state.url } });
          }
        }),
        map(authenticated => !!authenticated)
      );
  }


  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.isAuthenticated()
      .pipe(
        first(),
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['home'], { queryParams: { returnUrl: state.url } });
          }
        }),
        map(authenticated => !!authenticated)
      );
  }
}

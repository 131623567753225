import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ScrollService } from 'src/app/shared/services/scroll.service';

@Component({
  selector: 'app-scroll-to-top-button',
  templateUrl: './scroll-to-top-button.component.html',
  styleUrls: ['./scroll-to-top-button.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate('200ms', style({opacity: 1}))
    ]),
    transition(':leave', [
        animate('200ms', style({opacity: 0}))
    ])
    ])
  ]
})
export class ScrollToTopButtonComponent implements OnInit {

  scrollOffset = 0;
  constructor(private scroll: ScrollService) { }

  ngOnInit() {
    fromEvent(window, 'scroll')
    .pipe(
      debounceTime(500)
    )
    .subscribe(e => {
      console.log('scroll ', e);
      this.scrollOffset = window.pageYOffset;
    });
  }

  @HostListener('scroll', ['$event.target'])
  onScroll(evt: any) {
    console.log('scrolling ', evt);
    return false;
  }

  onClick() {
    this.scroll.scrollToTop();
  }
}

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import XpresUtils from '../../shared/xpres.util';


@Injectable()
export class MisPaquetesService {

  backendUrl: string = null;
  constructor(private _httpClient: HttpClient) {
    this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);
  }

  getMisPaquetes(page: number = 1, size: number = 10): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.request<Observable<HttpResponse<Object>>>('get',
      `${this.backendUrl}/envios/my-packages?page=${page}&size=${size}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json'
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const total = res.headers.get('Xpres-Count-Items');
          const rows = <any[]>res.body;

          return of({
            total: total,
            items: rows
          });
        })
      );
  }

  obtenerEstados(refs: string[]): Observable<any> {
    const observers: Observable<any>[] =
    refs.map(ref => {
      return this.obtenerEstadoNetSuite(ref);
    });

    return forkJoin(observers)
      .pipe(
        share(),
        catchError(err => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  obtenerEstadoNetSuite(trackingNumber: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.request<Observable<HttpResponse<Object>>>('get',
      `${this.backendUrl}/envios/status?n=${trackingNumber}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json'
      }).pipe(
        map((res: HttpResponse<Object>) => {
          return {
            key: trackingNumber,
            value: res['body']['estado']
          };
        }),
        catchError(err => {
          console.log(err);
          return throwError(err);
        })
      );
  }
}

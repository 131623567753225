import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import XpresUtils from '../xpres.util';

export interface ContactMessage {
  nombre: string;
  motivo: string;
  apellido: string;
  email: string;
  asunto: string;
  mensaje: string;
  telefono: string;
}

export interface ContactResult {
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  backendUrl: string;

  constructor(private http: HttpClient) {
    this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);

  }

  contactUs(m: ContactMessage): Observable<ContactResult> {
    const url = `${this.backendUrl}/contactMessage`;
    return this.http.post<ContactResult>(url, m);
  }
}
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-localidades',
  templateUrl: './localidades.component.html',
  styleUrls: ['./localidades.component.scss']
})
export class LocalidadesComponent implements OnInit, OnDestroy {

  _isMobile = false;
  bsModalRef: BsModalRef;

  @ViewChild('localidadesTemplate') elementView: ElementRef;

  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _auth: AuthService,
    private modalService: BsModalService,
    private _responsiveService: ResponsiveService
  ) {
    this._unsubscribeAll = new Subject();

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = (breackpoint === 'xs');
      });
    this._responsiveService.check();
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  show(): void {
    if (this._auth.isLoggedIn()) {
      this.bsModalRef = this.modalService.show(this.elementView, {
        class: 'mw-100' + (!this._isMobile ? ' w-75' : ''),
        backdrop: 'static',
        keyboard: false
      });
    }

    this.open.emit(null);
  }
}

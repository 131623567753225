import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CuponerasService } from './cuponeras.service';
import { of, Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoginService } from '../auth/login/login.service';
import { environment } from "../../../environments/environment";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute } from '@angular/router';
import XpresUtils from 'src/app/shared/xpres.util';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { catchError } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-cuponera',
  templateUrl: './cuponeras.component.html',
  styleUrls: ['./cuponeras.component.scss']
})
export class CuponerasComponent implements OnInit, OnDestroy {
  
  cuponerasLoaded = false;
  couponBooklets: any[] = [];
  callingMP = false;
  paramCouponPurchaseId: string = null;
  paramCouponPurchaseStatus: string = null;
  private _unsubscribeAll: Subject<any>;
  mobileMenuDisplayed = false;
  constructor(private _cuponerasService: CuponerasService, 
    private _auth: AuthService, 
    private _loginService: LoginService,
    private route: ActivatedRoute,
    private notifications: NotificationService,
    @Inject(DOCUMENT) private document: any) {
    this._unsubscribeAll = new Subject();
  }
  ngOnInit(): void {
    this._cuponerasService.getCuponeras()
      .subscribe(data => {
        this.couponBooklets = data;
        this.cuponerasLoaded = true;
      });
      this.route.paramMap.subscribe((params) => {
        const id = params.get('id');         // Retrieve the 'id' parameter
        const status = params.get('status'); // Retrieve the 'status' parameter
        this.route.queryParams.subscribe(queryParams => {
          const paymentId = queryParams['payment_id'];
          if(paymentId){
            if (XpresUtils.isValidUuid(id) && status == "-1" || status == "1" || status == "0") {
              this._cuponerasService.savePaymentResponse(id, {status: parseInt(status), paymentId: paymentId}).subscribe((response) => {
                if (response && response.status === "success") {
                  this.notifications.showSuccess("El pago fue aprobado");
                }else if(response && response.status === "failure"){
                  this.notifications.showError("El pago fue rechazado");
                }else if(response && response.status === "pending"){
                  this.notifications.showInfo("El pago se encuentra pendiente de confirmación");
                }
              });  
            }
          }
        });
        
      });
  }
  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  buyCoupon(coupon: any) : void {
    if (this._auth.isLoggedIn()) {
      this.callingMP = true;
      $(".buyBtns")
      .removeClass("btn-primary")
      .addClass("btn-secondary")
      .addClass("disabled")
      .html("Redireccionando...");
      this._cuponerasService.saveCouponPurchase({couponBooklet: coupon}).pipe(
        catchError((error) => {
          return of(null);
        })
      ).subscribe((data) => {
        if (data) { 
          if (data["internal"] === true) {
            this.document.location.href = data["url"];
          } else {
            this.document.location.href = environment.production
              ? data["init_point"]
              : data["sandbox_init_point"];
          }
        } else {
          this.notifications.showError("Hubo un error al intentar redireccionar");
          $(".buyBtns")
          .removeClass("btn-secondary")
          .addClass("btn-primary")
          .removeClass("disabled")
          .html("COMPRAR");
        }
      });
    } else {
      this._loginService.open();
    }
  }
}

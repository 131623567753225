import { EnvioService } from './../../envio/envio.service';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoginService } from './login.service';
import { UsuarioService } from '../../user/user.service';
import { environment } from '../../../../environments/environment';

declare var $: any;

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (!passwordConfirm.value) {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { 'passwordsNotMatching': true };
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  registerForm: FormGroup;
  forgotForm: FormGroup;

  loginSubmitted = false;
  registerSubmitted = false;
  forgotSubmitted = false;
  loading = false;

  tiposFiscales$: Observable<Array<any>>;
  departamentos$: Observable<Array<any>>;
  localidades$: Observable<Array<any>>;

  XPRES_SITE_KEY = `${environment.reCaptchaKey}`;

  @ViewChild('close') close: ElementRef;

  @Output()
  login: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('captchaRef') captchaRef: ElementRef;

  // Private
  private _unsubscribeAll: Subject<any>;
  mostrarDialogoRegistroCompleto = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _notificationService: NotificationService,
    private _userService: UsuarioService,
    private _loginService: LoginService,
    private _envioService: EnvioService) {

    this._unsubscribeAll = new Subject();
    this.departamentos$ = this._envioService.getDepartamentos();
    this.localidades$ = this._envioService.getLocalidades('-1');
  }

  ngOnInit() {
    this.tiposFiscales$ = this._userService.getTiposFiscales();

    this._buildForms();

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.registerForm.get('password').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.registerForm.get('passwordConfirm').updateValueAndValidity();
      });

    this._loginService.onLoginChanged.asObservable()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(l => {
        switch (l) {
          case 'open': this.show(); break;
          case 'close':
            this.onClose(); break;
          default: break;
        }
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  _buildForms(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.registerForm = this._formBuilder.group({
      name: [null, Validators.required],
      lastName: [null],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.pattern(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/)]],
      passwordConfirm: [null, [Validators.required, confirmPasswordValidator]],
      phone: [null, Validators.required],
      rut: [null, Validators.required],
      fiscalType: [null, Validators.required],
      state: [null, Validators.required],
      city: [null, Validators.required],
      address: [null, Validators.required],
      recaptchaReactive: new FormControl(null, Validators.required)
    });

    this.forgotForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.registerForm.controls['state'].reset();
    this.registerForm.controls['city'].reset();
  }

  // Convenience getters for easy access from the template to form fields.

  get lf() {
    return this.loginForm.controls;
  }

  get rf() {
    return this.registerForm.controls;
  }

  get ff() {
    return this.forgotForm.controls;
  }

  onLoginSubmit(): void {
    this.loginSubmitted = true;

    if (!this.loginForm.invalid) {
      const inputValue = this.loginForm.value;

      this._auth.login(inputValue.email, inputValue.password)
        .subscribe(
          loggedUser => {
            let name = ((loggedUser['firstName'] || '').trim() + ' ' + (loggedUser['lastName'] || '').trim()).trim();

            this._loginService.loggedIn();
            if (!name) {
              name = loggedUser['email'].split('@')[0];
            }
            this.close.nativeElement.click();
            this._notificationService.showInfo(`Bienvenido ${name}!`)
              .then(() => {
                console.log(loggedUser);
              });
          } ,
          (err) => {
	      if(err.error && err.error.message){
                throw (new Error(err.error.message));
	      }
	      throw err;
	  }
        );
    }
  }

  onClose(): void {
    // this._loginService.close();

    this.loginSubmitted = false;
    this.registerSubmitted = false;
    this.forgotSubmitted = false;

    const forms = [this.loginForm, this.registerForm, this.forgotForm];
    forms.forEach(form => {
      Object.keys(form.controls).forEach(key => {
        form.controls[key].reset();
        // form.controls[key].markAsTouched();
        form.controls[key].updateValueAndValidity();
      });

      // form.markAsTouched();
      form.updateValueAndValidity();
    });
  }

  onRegisterSubmit(): void {
    this.registerSubmitted = true;

    if (!this.registerForm.invalid) {
      const inputValue = this.registerForm.value;

      this._auth.register(inputValue)
        .subscribe(
          loggedUser => {
            /* let name = ((loggedUser['firstName'] || '').trim() + ' ' + (loggedUser['lastName'] || '').trim()).trim();

            if (!name) {
              name = loggedUser['email'].split('@')[0];
            } */
            this.close.nativeElement.click();
            /* this._notificationService.showInfo(`Bienvenido ${name}!`)
              .then(() => {
                console.log(loggedUser);
              }); */
            // this._notificationService.showInfo('Se ha enviado un email de verificación a la dirección de email proporcionada.');
            this.mostrarDialogoRegistroCompleto = true;
          },
          (err) => {
            throw (err);
          }
        );
    }
  }

  onForgotSubmit(): void {
    this.forgotSubmitted = true;

    if (!this.forgotForm.invalid) {
      const inputValue = this.forgotForm.value;

      this._auth.forgot(inputValue.email)
        .subscribe(
          loggedUser => {
            this.close.nativeElement.click();
            this._notificationService.showInfo('Verifique su casilla de correo!')
              .then(() => {
                // email enviado!
              });
          },
          (err) => {
            throw (err);
          }
        );
    }
  }

  show(showModal: string = null): void {
    // this._loginService.open();
    window['openLoginModal']();
  }

  onChangeDep(depId: string): void {
    this.registerForm.controls['city'].reset();
    this.localidades$ = this._envioService.getLocalidades(depId);
  }

  isInvalid(name: string): boolean {
    if (this.registerForm.controls && this.registerForm.controls[name]) {
      if (this.registerForm.controls[name]['errors'] && !this.registerForm.controls[name]['untouched']) {
        return true;
      }
    }
    return false;
  }

  //TODO agrego esto

  registerHasError(error: string, fieldName: string): boolean {
    return this._formHasError(this.registerForm, error, fieldName);
  }
  loginHasError(error: string, fieldName: string): boolean {
    return this._formHasError(this.loginForm, error, fieldName);
  }

  _formHasError(form: FormGroup, error: string, fieldName: string): boolean {

    if (form.controls && form.controls[fieldName]) {
      if (
        form.controls[fieldName].hasError(error) &&
        !form.controls[fieldName].untouched
      ) {
        return true;
      }
    }
    return false;
  }

  hasError(error: string, fieldName: string): boolean {
    if (this.registerForm.controls && this.registerForm.controls[fieldName]) {
      if (this.registerForm.controls[fieldName].hasError(error) && !this.registerForm.controls[fieldName].untouched) {
        return true;
      }
    }
    return false;
  }
}

export const environment = {
  production: true,
  hmr: false,
  endpoint: 'https://xpres.com.uy/api/v1.0',
  privateEndpoint: 'https://192.168.1.64:3010/api/v1.0',
  tokenKeyName: '_xpres__',
  expiresKeyName: '_expAt__',
  googleApiKey: 'AIzaSyDpDemFnDB55OTU77v_07YczKV1zVY8uxc',
  // googleApiKey: 'AIzaSyDzObbeZLlbdu-_IktYU1BGgZvtx3TCabo',
  integrateRejectedPackages: 0,
  reCaptchaKey: '6Le2Ur0UAAAAANsqpKNuRFfQ2z09fKTssyfaQwc1',
  reCaptchaSecret: '6Le2Ur0UAAAAAGjirD7iSO_OINvFnsBY7ER674RL'
};

import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AsistenteComponent } from '../../asistente/asistente.component';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoginService } from '../../auth/login/login.service';
import { LoginComponent } from '../../auth/login/login.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollService } from 'src/app/shared/services/scroll.service';
import { AsistenteService } from '../../asistente/asistente.service';
import { TermsAndConditionsComponent } from '../../terms-and-conditions/terms-and-conditions.component';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  _showAfterLogin = false;

  // @ViewChild(LoginComponent) login: LoginComponent;
  // @ViewChild(AsistenteComponent) asistente: AsistenteComponent;
  @ViewChild(TermsAndConditionsComponent) termsAndConditions: TermsAndConditionsComponent;

  private _unsubscribeAll: Subject<any>;

  constructor(private _responsiveService: ResponsiveService,
    private _auth: AuthService,
    private ref: ChangeDetectorRef,
    private _loginService: LoginService,
    private _scroll: ScrollService,
    private _asistente: AsistenteService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this._loginService.onLoginChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(mode => {

        if (mode === 'loggedin') {
          if (this._showAfterLogin === true) {
            setTimeout(() => {
              this._asistente.show();
            }, 1000);
          }
          this._showAfterLogin = false;

        } else if (mode === 'close') {
          this._showAfterLogin = false;
        }

      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  crearEnvio(): void {
    if (this._auth.isLoggedIn()) {
      this._asistente.show();
    } else {
      this._showAfterLogin = true;
      // this.login.show('asistente');
      this._loginService.open();
    }
  }

  goToSection(e: any, sectionName: string): void {
    e.preventDefault();

    const position = $('#' + sectionName).offset().top;

    $('body, html').animate({
      scrollTop: position
    } /* speed */);
  }

  scrollTo(name: string) {
    this._scroll.goToSection(name);
  }

  showConditions(): void {
    this.termsAndConditions.show();
  }
}


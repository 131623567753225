import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import XpresUtils from 'src/app/shared/xpres.util';
import { environment } from 'src/environments/environment';

interface IAddressReturn {
  departamentos: any[];
  addresses: any[];
  montevideo: any;
}


@Injectable()
export class AddressService {

  backendUrl: string = null;
  constructor(private _httpClient: HttpClient) {
    this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);
  }

  getData(currentUserId: string): Observable<IAddressReturn> {
    const observers: Observable<any>[] = [
      this.getDepartamentos(),
      this.getAddresses(currentUserId),
      this.getDepartamentoMvd()
    ];

    return forkJoin(observers)
      .pipe(
        map(results => ({
          departamentos: (<any[]>(results[0].body)),
          addresses: (<any[]>(results[1].body['direcciones'])),
          montevideo: results[2].body
        }),
          catchError(err => throwError(err))
        ));
  }

  getDepartamentos(): Observable<any> {
    return this._httpClient.get<Observable<Array<any>>>(
      `${this.backendUrl}/departamentos?sort[nombre]=1&size=100`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  getAddresses(userId: string): Observable<any> {
    return this._httpClient.get<Observable<Array<any>>>(
      `${this.backendUrl}/users/${userId}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  getDepartamentoMvd(): Observable<any> {
    return this._httpClient.get<Observable<any>>(
      `${this.backendUrl}/departamentos/mvd`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  addAddress(userId: string, data: any): Observable<any> {
    const body = data;

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.request<Observable<HttpResponse<Object>>>('post',
      `${this.backendUrl}/users/${userId}/addresses`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const addresses = res.body;
          return of(addresses);
        })
      );
  }

  updateAddress(userId: string, data: any): Observable<any> {
    const body = data;

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.request<Observable<HttpResponse<Object>>>('put',
      `${this.backendUrl}/users/${userId}/addresses/${data['id']}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const addresses = res.body;
          return of(addresses);
        })
      );
  }

  removeAddress(userId: string, addressId: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.request<Observable<HttpResponse<Object>>>('delete',
      `${this.backendUrl}/users/${userId}/addresses/${addressId}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json'
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const addresses = res.body;
          return of(addresses);
        })
      );
  }
}

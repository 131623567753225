import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { EnvioService } from './../../../envio/envio.service';
import { PickupCentersService } from './../service/pickup-centers.service';
import { IPickupCenter, PickupCenter } from './../service/pickup.model';

declare var $: any;

@Component({
  selector: 'app-lista-centros',
  templateUrl: './lista-centros.component.html',
  styleUrls: ['./lista-centros.component.scss']
})
export class ListaCentrosComponent implements OnInit, OnDestroy {

  _isMobile = false;
  _isNew = false;

  centros$: Observable<any>;
  departamentos$: Observable<Array<any>>;
  localidades$: Observable<Array<any>>;

  filter: string;
  size: number;
  p: number;
  total: number;
  loading: boolean;

  searchForm: FormGroup;
  form: FormGroup;

  centro: IPickupCenter = null;

  private _unsubscribeAll: Subject<any>;
  private searchTextChanged = new Subject<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private _pickupCentersService: PickupCentersService,
    private _responsiveService: ResponsiveService,
    private _notificationService: NotificationService,
    private _envioService: EnvioService
  ) {
    this.filter = '';
    this.p = 1;
    this.size = 10;
    this._unsubscribeAll = new Subject();

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = (breackpoint === 'xs');
      });
    this._responsiveService.check();

    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap(search => {
          this.filter = search;
          return of(null);
        })
      )
      .subscribe(() => {
        this.getPage(1);
      });
  }

  ngOnInit() {
    this.departamentos$ = this._envioService.getDepartamentos();
    this.localidades$ = this._envioService.getLocalidades('-1');

    this._buildForms();
    this.getPage(1);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  _buildForms(): void {
    this.searchForm = this._formBuilder.group({
      search: [''],
      state: [null]
    });

    this.form = this._formBuilder.group({
      id: [null],
      codigo: [null, Validators.required],
      nombre: [null, Validators.required],
      state: [null, Validators.required],
      city: [null, Validators.required],
      calle: [''],
      nroPuerta: [''],
      adicional: [''],
      latitud: [''],
      longitud: [''],


      //TODO esto ya no se usaria
      horario: [null],
      lun: [null],
      mar: [null],
      mie: [null],
      jue: [null],
      vie: [null],
      sab: [null],
      dom: [null]
    });
  }

  getPage(page: number) {
    this.loading = true;
    const departamentoId = this.searchForm.get('state').value;
    this.centros$ = this._pickupCentersService.getPage(this.filter, 'departamento', 'asc', page, this.size, departamentoId)
      .pipe(
        tap(res => {
          this.total = res.totalCount;
          this.p = page;
          this.loading = false;
        }),
        map(res => {
          return res['rows'];
        })
      );
  }

  formIsInvalid(name): boolean {
    if (this.form.controls && this.form.controls[name]) {
      if (this.form.controls[name]['errors'] && !this.form.controls[name]['untouched']) {
        return true;
      }
    }
    return false;
  }

  formHasError(error: string, fieldName: string): boolean {
    if (this.form.controls && this.form.controls[fieldName]) {
      if (this.form.controls[fieldName].hasError(error) && !this.form.controls[fieldName].untouched) {
        return true;
      }
    }
    return false;
  }

  showDetail(itemId: string): void {
    $('.slider').animate({ 'margin-right': '0' }, 200);
    this._isNew = false;

    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].reset();
    });

    this._pickupCentersService.getPickupCenter(itemId)
      .subscribe(resp => {
        console.log(resp);
        this.centro = resp;
        this.form.controls['id'].setValue(this.centro.id);
        this.form.controls['codigo'].setValue(this.centro.codigo);
        this.form.controls['nombre'].setValue(this.centro.nombre);
        this.form.controls['calle'].setValue(this.centro.calle || '');
        this.form.controls['nroPuerta'].setValue(this.centro.nroPuerta || '');
        this.form.controls['adicional'].setValue(this.centro.adicional || '');
        this.form.controls['latitud'].setValue(this.centro.latitud || '');
        this.form.controls['longitud'].setValue(this.centro.longitud || '');

        if (this.centro.localidad) {
          this.form.controls['state'].setValue(this.centro.localidad.departamento.id);
          this.localidades$ = this._envioService.getLocalidades(this.centro.localidad.departamento.id);
          if (this.centro.localidad) {
            this.form.controls['city'].setValue(this.centro.localidad.id);
          }
        }

        const arrHorario = this.horario(this.centro.horario);
        console.log(this.centro.horario);
        //TODO defino el horario asi
        this.form.controls['horario'].setValue(this.centro.horario);

        //TODO esto ya no iria
        // for (let i = 0; i < arrHorario.length; i++) {
        //   const name = arrHorario[i][0].substr(0, 3).toLowerCase().replace('é', 'e').replace('á', 'a');
        //   this.form.controls[name].setValue(arrHorario[i][1]);
        // }
      });
  }


  _getDayInfo(data: string[] = [], dow: number = 1): string {
    const idx = (dow - 1) * 4;
    const open1 = (idx < data.length ? (data[idx]).trim() : '');
    const close1 = (idx + 1 < data.length ? (data[idx + 1]).trim() : '');
    const open2 = (idx + 2 < data.length ? (data[idx + 2]).trim() : '');
    const close2 = (idx + 3 < data.length ? (data[idx + 3]).trim() : '');
    let result = '';

    if (open1 && close1) {
      result = open1 + ' a ' + close1;
      if (open2 && close2) {
        result += ' y ' + open2 + ' a ' + close2;
      }
    } else {
      result = open1;
    }
    return result;
  }

  horario(openHours: string = ''): string[][] {
    if (openHours) {
      const data = (openHours || '').split(';');
      const days = [
        ['Lunes', this._getDayInfo(data, 1)],
        ['Martes', this._getDayInfo(data, 2)],
        ['Miércoles', this._getDayInfo(data, 3)],
        ['Jueves', this._getDayInfo(data, 4)],
        ['Viernes', this._getDayInfo(data, 5)],
        ['Sábado', this._getDayInfo(data, 6)],
        ['Domingo', this._getDayInfo(data, 7)]
      ];
      return days.filter(d => d.length > 0 && d[1] !== '');
    }
    return [];
  }

  hideDetail(): void {
    $('.slider').animate({ 'margin-right': '-100%' }, 200);
    this.centro = new PickupCenter();
    this._isNew = false;
  }

  addNew(): void {
    $('.slider').animate({ 'margin-right': '0' }, 200);

    this._isNew = true;
    this.centro = new PickupCenter();
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].reset();
    });
  }

  onSearchType(value: string): void {
    this.searchTextChanged.next(value);
  }

  onChangeDepartamento(evt): void {
    this.getPage(1);
  }

  onChangeFormDepartamento(depId: string): void {
    this.form.controls['city'].reset();
    this.localidades$ = this._envioService.getLocalidades(depId);
  }

  horarioPorDia(key) {
    const value = this.form.get(key).value;
    const values = ['', '', '', ''];
    const splittedValues = (value || '').toLowerCase().split('y');

    const matutino = splittedValues[0].split('a');
    const vespertino = (splittedValues.length > 1 ? splittedValues[1] : '').split('a');

    values[0] = (matutino.length > 0 ? matutino[0] : '').trim();
    values[1] = (matutino.length > 1 ? matutino[1] : '').trim();
    values[2] = (vespertino.length > 0 ? vespertino[0] : '').trim();
    values[3] = (vespertino.length > 1 ? vespertino[1] : '').trim();

    return values;
  }

  //TODO esto no deberia estar 
  /*
    saveCentro() {
      if (!this.form.valid) {
        Object.keys(this.form.controls).forEach(key => {
          this.form.controls[key].markAsTouched();
          this.form.controls[key].updateValueAndValidity();
        });
        return;
      }
  
      this.centro = new PickupCenter({
        id: this.form.get('id').value,
        codigo: this.form.get('codigo').value,
        nombre: this.form.get('nombre').value,
        calle: this.form.get('calle').value,
        nroPuerta: this.form.get('nroPuerta').value,
        adicional: this.form.get('adicional').value,
        latitud: this.form.get('latitud').value,
        longitud: this.form.get('longitud').value,
        localidad: {
          id: this.form.get('city').value,
          departamento: {
            id: this.form.get('state').value
          }
        }
      });
  
      let values = [];
      ['lun', 'mar', 'mie', 'jue', 'vie', 'sab', 'dom'].forEach(key => {
        values = values.concat(this.horarioPorDia(key));
      });
      this.centro.horario = values.join(';');
  
      if (!this._isNew) {
        this._pickupCentersService.guardarPickupCenter(this.centro)
          .subscribe(() => {
            this._notificationService.showInfo('Cambios almacenados exitosamente.', 'Modificar centro XPres');
            this.hideDetail();
            this.getPage(this.p);
          });
      } else {
        this._pickupCentersService.crearPickupCenter(this.centro)
          .subscribe(() => {
            this._notificationService.showInfo('Centro creado exitosamente.', 'Agregar centro XPres');
            this.hideDetail();
            this.getPage(this.p);
          });
      }
    }
  
    deleteCentro() {
      this._pickupCentersService.eliminarPickupCenter(this.centro)
        .subscribe(() => {
          this._notificationService.showInfo('Centro eliminado.', 'Eliminar centro XPres');
          this.hideDetail();
          this.getPage(this.p);
        });
    }
  */
}

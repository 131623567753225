import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable()
export class ResponsiveService {
  getCurrentBreakpoint = new Subject();

  constructor(public breakpointObserver: BreakpointObserver) {
    this.checkBreakpoint();
  }

  check(): void {
    this.checkBreakpoint();
  }

  getBreakpoint(): Observable<any> {
    return this.getCurrentBreakpoint.asObservable();
  }

  private checkBreakpoint(): void {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        this.getCurrentBreakpoint.next('xs');
      }
      if (result.breakpoints[Breakpoints.Small]) {
        this.getCurrentBreakpoint.next('sm');
      }
      if (result.breakpoints[Breakpoints.Medium]) {
        this.getCurrentBreakpoint.next('md');
      }
      if (result.breakpoints[Breakpoints.Large]) {
        this.getCurrentBreakpoint.next('lg');
      }
      if (result.breakpoints[Breakpoints.XLarge]) {
        this.getCurrentBreakpoint.next('xl');
      }
    });
  }
}

import { NotificationService } from './../../shared/services/notification.service';
import { AuthService } from './../../shared/auth/auth.service';
// import { NotificationService } from 'src/app/shared/services/notification.service';
import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, TemplateRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnvioService } from '../envio/envio.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HomeService } from '../home/home.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollService } from 'src/app/shared/services/scroll.service';
declare var $: any;

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit, OnDestroy {

  form: FormGroup;
  searchText: string;
  loading = false;

  bsModalRef: BsModalRef;
  @ViewChild('template') elementView: ElementRef;

  @ViewChild('trackingNumber') input: ElementRef;

  @Input() mini = false;
  @Output() search: EventEmitter<any> = new EventEmitter();

  _unsubscribeAll = new Subject();

  constructor(// private _notificationService: NotificationService,
    private _formBuilder: FormBuilder,
    private _modalService: BsModalService,
    private _envioService: EnvioService,
    private _auth: AuthService,
    private _notificationService: NotificationService,
    private scroll: ScrollService,
    private service: HomeService
  ) { }

  ngOnDestroy(): void {

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.searchText = '';

    this.form = this._formBuilder.group({
      trackingNumber: ['', Validators.required]
    });

    // this.form.get('trackingNumber').valueChanges.subscribe(val => {
    //   this.searchText = val;
    // });

    // this.focusSearchInput();

    this.service.observeFocusSearchInput()
      .pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(() => this.focusSearchInput());
  }

  public focusSearchInput(): void {

    this.input.nativeElement.select();
    this.input.nativeElement.focus();
    this.scroll.scrollToTop();

  }

  isValid(): boolean {
    return this.form.valid;
  }

  onButtonClick(evt: any): void {
    this.searchText = this.form.get('trackingNumber').value;

    if (this.mini === true) {
      $('#dropdownTracking').dropdown('toggle');
      this.form.get('trackingNumber').setValue('');
    }
    this.search.emit(null);

    this.bsModalRef = this._modalService.show(this.elementView, { class: 'modal-lg' });
  }

}

import { Injectable, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { timeout } from 'q';

@Injectable()
export class NotificationService {

  constructor(private toastr: ToastrService, private ngZone: NgZone) {
  }

  showSuccess(message: string, title = '', timeOut = 2000): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ngZone.run(() => {
        this.toastr.success(message, title, {
          timeOut: timeOut
        })
          .onTap
          .pipe(take(1))
          .subscribe(resolve, reject);
      }, 0);
    });
  }

  showInfo(message: string, title = '', timeOut = 2000): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ngZone.run(() => {
        this.toastr.info(message, title, {
          timeOut: timeOut
        })
          .onTap
          .pipe(take(1))
          .subscribe(resolve, reject);
      }, 0);
    });
  }

  showError(message: string, title = 'Error', timeOut = 5000): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ngZone.run(() => {
        setTimeout(() => {
          this.toastr.error(message, title, {
            timeOut: timeOut
          })
            .onTap
            .pipe(take(1))
            .subscribe(resolve, reject);
        }, 0);
      });
    });
  }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { TarifasService } from './tarifas.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { HeaderMenuService } from 'src/app/shared/services/header-menu.service';

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.scss']
})
export class TarifasComponent implements OnInit, OnDestroy {

  tarifasLoaded = false;
  tablasTarifas: any[] = [];
  tablaTiempos: any[] = [];
  detalleImpuestos: string;
  _isMobile = false;

  @ViewChild(TermsAndConditionsComponent) termsAndConditions: TermsAndConditionsComponent;

  private _unsubscribeAll: Subject<any>;
  mobileMenuDisplayed = false;

  constructor(private _tarifasService: TarifasService,
    private _responsiveService: ResponsiveService,
    private menu: HeaderMenuService) {

    this._unsubscribeAll = new Subject();

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = breackpoint === 'xs';
      });
  }

  // Replace all non-digit characters with an empty string,
  // and convert to Number.
  _getNumberFromString(type: string): number {
    return Number(type.replace(/\D/g, ""));
  }

  ngOnInit(): void {
    this.menu.getMobileMenuDisplayed()
     .pipe(takeUntil(this._unsubscribeAll))
     .subscribe(d => this.mobileMenuDisplayed = d);
    this._tarifasService.getData()
      .subscribe(data => {
        this.tablasTarifas = [];

        let idx = 0;
        let tarifa: string, servicio: string, origen: string, destino: string, row: any;

        // Tablas de tarifas
        while (idx < data.tarifas.length) {
          tarifa = data.tarifas[idx];
          servicio = tarifa['servicio'];

          this.tablasTarifas.push({
            title: servicio,
            rows: []
          });

          while (idx < data.tarifas.length && tarifa['servicio'] === servicio) {
            destino = tarifa['destino'];
            row = { destino, tipos: [] };

            while (idx < data.tarifas.length && tarifa['servicio'] === servicio && tarifa['destino'] === destino) {
              row.tipos.push({ tipo: data.tarifas[idx]['tipo'], import: data.tarifas[idx]['importe'] });

              idx++;
              if (idx < data.tarifas.length) {
                tarifa = data.tarifas[idx];
              }
            }
            row.tipos.sort((a: any, b: any) => {
              return this._getNumberFromString(a.tipo) - this._getNumberFromString(b.tipo);
            });

            this.tablasTarifas[this.tablasTarifas.length - 1].rows.push(row);
          }
        }

        // Tabla de tiempos
        idx = 0;
        this.tablaTiempos = [];
        while (idx < data.tiempos.length) {
          origen = data.tiempos[idx]['origen'];
          this.tablaTiempos.push({ origen, columns: [] });

          while (idx < data.tiempos.length && data.tiempos[idx]['origen'] === origen) {
            this.tablaTiempos[this.tablaTiempos.length - 1]['columns'].push({
              destino: data.tiempos[idx]['destino'],
              tiempo: data.tiempos[idx]['tiempo']
            });
            idx++;
          }
        }

        // Detalle de impuestos
        this.detalleImpuestos = data.impuestos.map(impuesto => {
          return '' + impuesto.percent + '% ' + impuesto.name;
        }).join(' + ');

        this.tarifasLoaded = true;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  showConditions(): void {
    this.termsAndConditions.show();
  }
}


import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../shared/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LocalidadesComponent } from '../../admin/localidades/localidades.component';
import { PickupCentersComponent } from '../../admin/pickup-centers/pickup-centers.component';
import { AsistenteComponent } from '../../asistente/asistente.component';
import { LoginComponent } from '../../auth/login/login.component';
import { MisPaquetesComponent } from '../../mis-paquetes/mis-paquetes.component';
import { ResetPasswordComponent } from '../../user/reset-password/reset-password.component';
import { UserComponent } from '../../user/user/user.component';
import { MyCouponBookletComponent } from '../../my-coupon-booklets/my-coupon-booklets.component';

let $: any;

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit, OnDestroy {

  currentUser: any = null;
  resetToken: string = null;

  private _unsubscribeAll: Subject<any>;
  private verificationId: string = null;

  @ViewChild(ResetPasswordComponent) resetPassword: ResetPasswordComponent;


  @ViewChild(LoginComponent) login: LoginComponent;
  @ViewChild(AsistenteComponent) asistente: AsistenteComponent;
  @ViewChild(UserComponent) user: UserComponent;
  @ViewChild(MisPaquetesComponent) misPaquetes: MisPaquetesComponent;
  @ViewChild(LocalidadesComponent) localidades: LocalidadesComponent;
  @ViewChild(MyCouponBookletComponent) myCouponBooklets: MyCouponBookletComponent;
  @ViewChild(PickupCentersComponent) centros: PickupCentersComponent;


  paramEnvioId: string = null;
  paramStatus: string = null;


  constructor(
    private _auth: AuthService,
    private router: Router,
    private _notificationService: NotificationService,
    private route: ActivatedRoute) {  this._unsubscribeAll = new Subject();}

  ngOnInit() {
    const qs: string[] = this.route.snapshot.queryParamMap.keys;
    this.verificationId = this.route.snapshot.params.verificationId;
    this.resetToken = this.route.snapshot.params.resetToken;
    // console.log('QueryString:' + qs.join(','));

    this._auth.currentUser
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(user => {
        this.currentUser = user;
      });

    if (this._auth.isLoggedIn()) {
      if (!(this.currentUser || {})['email']) {
        this._auth.refreshUser().subscribe();
      }
    }

    if (this.verificationId) {
      this._auth.verify(this.verificationId)
        .subscribe(user => {
          this._notificationService.showInfo('Gracias por verificar su cuenta. Ya puede ingresar utilizando su usuario y contraseña.')
            .then(() => this.goHome());
        }, err => {
          this._notificationService.showError(err.error.error.message)
          .then(() => this.goHome());
        });
    } else if (this.resetToken) {
      this._auth.checkResetToken(this.resetToken)
        .subscribe(info => {
          this.resetPassword.show();
        }, err => {
          this._notificationService.showError(err.error.error.message)
            .then(() => this.goHome());
        });
    }
  }
  goHome() {
    this.router.navigate(['home'], { queryParams: { ts: new Date().getTime().toString(36) } });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  isAuthenticated(): boolean {
    return this.currentUser !== null;
  }

  contactUs(e: any): void {
    e.preventDefault();

    const position = $('#section-contact').offset().top;

    $('body, html').animate({
      scrollTop: position
    } /* speed */);
  }

  showProfile(): void {
    if (this._auth.isLoggedIn()) {
      this.user.show();
    }
  }

  showPackages(): void {
    if (this._auth.isLoggedIn()) {
      this.misPaquetes.show();
    }
  }

  showMyCouponBooklets(): void {
    if (this._auth.isLoggedIn()) {
      this.myCouponBooklets.show();
    }
  }

  showAdminLocalidades(): void {
    if (this._auth.isLoggedIn()) {
      this.localidades.show();
    }
  }

  showAdminPuntos(): void {
    if (this._auth.isLoggedIn()) {
      this.centros.show();
    }
  }

  onOpenEnvio(event: any): void {
    this.paramEnvioId = null;
  }

  onOpenMisPaquetes(event: any): void { }

  onOpenMyCouponBooklets(event: any): void { }

  onOpenLocalidades(event: any): void { }

  onOpenCentros(event: any): void { }

}

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { environment } from './../../../environments/environment';
import { IUser, User } from './user.model';
import XpresUtils from '../../shared/xpres.util';

interface IUsuarioReturn {
  departamentos: any[];
  usuario: IUser;
}

@Injectable()
export class UsuarioService {

  backendUrl: string = null;

  constructor(private _httpClient: HttpClient,
    private _auth: AuthService) {
    this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);
  }

  cargarDatos(): Observable<IUsuarioReturn> {
    const observers: Observable<any>[] = [
      this.getDepartamentos(),
      this._auth.refreshUser()
    ];

    return forkJoin(observers)
      .pipe(
        map(results => ({
          departamentos: (<any[]>(results[0].body)),
          usuario: new User(results[1])
        }),
          catchError(err => throwError(err))
        ));
  }

  getDepartamentos(): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams
      ()
      .set('page', '1')
      .set('size', '10000');

    return this._httpClient.get<Observable<Array<any>>>(
      `${this.backendUrl}/departamentos`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const rows = <any[]>(res.body);
          const departamentos = rows.map(item => ({ 'id': item['id'], 'nombre': item['nombre'] }));

          return of(departamentos);
        })
      );
  }

  getLocalidades(id: string = '-1'): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams()
      .set('page', '1')
      .set('size', '10000');

    if (XpresUtils.isValidUuid(id)) {
      return this._httpClient.get<Observable<Array<any>>>(
        `${this.backendUrl}/localidades?where[departamentoId]=${id}`,
        {
          headers: httpHeaders,
          observe: 'response',
          responseType: 'json',
          params: params
        }).pipe(
          switchMap((res: HttpResponse<Object>) => {
            const rows = <any[]>(res.body);

            const localidades = rows.map(item => ({ 'id': item['id'], 'nombre': item['nombre'] }));
            return of(localidades);
          })
        );
    } else {
      return of([]);
    }
  }

  updateUser(usuario: IUser): Observable<any> {
    const body = {
      firstname: usuario.firstName,
      lastname: usuario.lastName,
      email: usuario.email,
      address: usuario.street,
      doorNumber: usuario.doorNumber,
      additionalAddress: usuario.additional,
      documentNumber: usuario.documentNumber,
      phone: usuario.phoneNumber,
      localidadId: (usuario.city ? usuario.city.id : null),
      tipoFiscalId: (usuario.tipoFiscal ? usuario.tipoFiscal.id : null)
    };

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.request<Observable<HttpResponse<Object>>>('patch',
      `${this.backendUrl}/users/${usuario.id}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          return of({});
        })
      );
  }

  changePassword(userId: string, currentPassword: string, newPassword: string): Observable<any> {
    const body = {
      userId, currentPassword, newPassword
    };

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.request<Observable<HttpResponse<Object>>>('post',
      `${this.backendUrl}/users/changepassword`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          return of({});
        })
      );
  }

  resetPassword(token: string, password: string): Observable<any> {
    const body = {
      password
    };

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.request<Observable<HttpResponse<Object>>>('post',
      `${this.backendUrl}/reset/${token}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          return of({});
        })
      );
  }

  getTiposFiscales(): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams
      ()
      .set('page', '1')
      .set('size', '10000');

    return this._httpClient.get<Observable<Array<any>>>(
      `${this.backendUrl}/tiposFiscales`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const rows = <any[]>(res.body);
          const tiposFiscales = rows.map(item => ({ 'id': item['id'], 'codigo': item['codigo'], 'nombre': item['nombre'] }));

          return of(tiposFiscales);
        })
      );
  }
}

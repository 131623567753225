import XpresUtils from 'src/app/shared/xpres.util';

export interface ILocalidad {
  id: string;
  codigo: number;
  nombre: string;
  departamento: {
    id: string,
    codigo: number;
    nombre: string;
  } | null;
}

export class Localidad implements ILocalidad {
  id;
  codigo;
  nombre;
  departamento;

  constructor(localidad?) {
    localidad = localidad || { departamento: null };
    this.id = localidad.id || XpresUtils.generateGUID();
    if (localidad.codigo) {
      this.codigo = localidad.codigo;
    }
    this.nombre = localidad.nombre || '';
    if (localidad.departamento) {
      this.departamento = {
        id: localidad.departamento.id,
        codigo: localidad.departamento.codigo,
        nombre: localidad.departamento.nombre
      };
    } else {
      this.departamento = null;
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AsistenteService {

  onShow = new Subject<string>();
  onClose = new Subject();

  constructor() { }

  observeShow(): Observable<string> {
    return this.onShow.asObservable();
  }

  observeClose(): Observable<unknown> {
    return this.onClose.asObservable();
  }

  show(envioId: string = null): void {
    this.onShow.next(envioId);
  }

  close(): void {
    this.onClose.next();
  }
}

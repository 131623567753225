import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, empty, from, Observable, of, Subject } from 'rxjs';
import { combineAll, distinctUntilChanged, flatMap, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { ResponsiveService } from './responsive.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderMenuService {
  breakpoint: Observable<string>;

  unsubscribeAll = new Subject();
  isMobile: boolean;

  mobileMenuState: Subject<boolean> = new BehaviorSubject(false);

  constructor(private responsive: ResponsiveService) {
    this.breakpoint = responsive.getBreakpoint();

    this.breakpoint
      .subscribe(breakpoint => {
        this.isMobile = breakpoint !== 'xl' && breakpoint !== 'lg';
      });
    responsive.check();
  }

  getMobileMenuDisplayed(): Observable<boolean> {
    return combineLatest([this.mobileMenuState.asObservable(), this.breakpoint])
      .pipe(
        map(([disp, bp]) => {
          if (bp === 'xl' || bp === 'lg') {
            return false;
          } else {
            return disp;
          }
        }));
    // return this.mobileMenuState.asObservable()
    // .pipe(
    //   switchMap(b => {
    //     if (this.isMobile) {
    //       return of(b);
    //     }
    //     return of(false);
    //   })
    // );
  }

  setMobileMenuDisplayed(v: boolean) {
    this.mobileMenuState.next(v);
  }
}

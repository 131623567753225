import { EnvioService } from './../../envio/envio.service';
import { IShipment, Shipment } from './../../envio/shipment.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';
import { MisPaquetesService } from '../mis-paquetes.service';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';

declare var $: any;

@Component({
  selector: 'app-lista-paquetes',
  templateUrl: './lista-paquetes.component.html',
  styleUrls: ['./lista-paquetes.component.scss']
})
export class ListaPaquetesComponent implements OnInit, OnDestroy {

  _isMobile = false;

  misPaquetes$: Observable<any>;
  size: number;
  p: number;
  total: number;
  loading: boolean;
  envio: IShipment = null;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _misPaquetesService: MisPaquetesService,
    private _responsiveService: ResponsiveService,
    private _envioService: EnvioService,
  ) {
    this.p = 1;
    this.size = 10;
    this._unsubscribeAll = new Subject();
    this.envio = new Shipment();

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = (breackpoint === 'xs');
      });
    this._responsiveService.check();
  }

  ngOnInit() {
    this.getPage(1);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  getPage(page: number) {
    this.loading = true;
    this.misPaquetes$ = this._misPaquetesService.getMisPaquetes(page, this.size)
      .pipe(
        tap(res => {
          this.total = res.total;
          this.p = page;
          this.loading = false;

          const refs = res.items.map(item => item['clientReference'] || item['id']);
          this._misPaquetesService.obtenerEstados(refs)
            .subscribe({
              next: values => {
                values.forEach(item => {
                  const id = '#ST-' + item['key'];
                  const val = item['value'];
                  $(id).html(val);
                });
              },
              error: err => console.error(err),
              complete: () => console.log('This is how it ends!'),
            });
        }),
        map(res => res.items)
      );
  }

  showDetail(itemId: string): void {
    $('.slider').animate({ 'margin-right': '0' }, 200);
    this._envioService.cargarEnvio({ envioId: itemId, status: 0})
      .subscribe(envio => {
        this.envio = envio;
      },
      err => {
        console.log(err);
        this.envio = new Shipment();
      });
  }

  hideDetail(): void {
    $('.slider').animate({ 'margin-right': '-100%' }, 200);
    this.envio = new Shipment();
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { PuntosService } from './puntos.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { takeUntil } from 'rxjs/operators';
import { AgmMap, AgmCoreModule } from '@agm/core';
import { Centro } from '../../shared/models/centro';
// import * from ''
declare var $: any;

@Component({
  selector: 'app-puntos',
  templateUrl: './puntos.component.html',
  styleUrls: ['./puntos.component.scss']
})
export class PuntosComponent implements OnInit {

  @ViewChild(AgmMap) private map: AgmMap;

  _isMobile = false;

  initialValue: any = {};
  departamentos: any[] = [];
  centros: Centro[] = [];

  config: any = {};
  pickerForm: FormGroup;
  openedWindow: string = null;

  zoom = 8;
  lat:number;
  long:number;

  private _unsubscribeAll: Subject<any>;


  constructor(private _formBuilder: FormBuilder,
    private _responsiveService: ResponsiveService,
    private _puntosService: PuntosService,
    private _notificationService: NotificationService
  ) {

    this._unsubscribeAll = new Subject();

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = breackpoint === 'xs';
      });
  }

  ngOnInit() {
    this.pickerForm = this._formBuilder.group({ state: [''] });
    this._puntosService.getData()
      .subscribe(data => {
        this.departamentos = data.departamentos;
        this.initialValue = data.montevideo;
        this.centros = data.centros;
        $('.ps__rail-y').css('z-index', 1000);

        this.pickerForm.controls['state'].setValue(this.initialValue.id);
      });
  }

  onChangeXpresDep(depId): void {
    this._puntosService.getCentrosPorDepartamento(depId)
      .subscribe(resp => {
        this.centros = resp.centros as Centro[];
        if (this.centros.length == 0)
          this._notificationService.showInfo('Todavía no hay pickups asignados para este departamento.')

        this.initialValue = resp.departamento;

        $('.ps__rail-y').css('z-index', 1000);
      });

  }

  verCentro(c: Centro) {
    this.lat = c.latitude;
    this.long = c.longitude;
    this.zoom = 19;
    this.openWindow(c.id);
  }

  isFarmashop(centro: Centro) {
    if (!centro.nombre) return false;
    return centro.nombre.includes("Farmashop");
  }

  openWindow(id: string): void {
    this.openedWindow = id;
  }

  isInfoWindowOpen(id: string): boolean {
    return this.openedWindow === id;
  }

  compareObjects(o1: object, o2: object): boolean {
    return o1 && o2 && o1['id'] === o2['id'];
  }

  _getDayInfo(data: string[] = [], dow: number = 1): string {
    const idx = (dow - 1) * 4;
    const open1 = (idx < data.length ? (data[idx]).trim() : '');
    const close1 = (idx + 1 < data.length ? (data[idx + 1]).trim() : '');
    const open2 = (idx + 2 < data.length ? (data[idx + 2]).trim() : '');
    const close2 = (idx + 3 < data.length ? (data[idx + 3]).trim() : '');
    let result = '';

    if (open1 && close1) {
      result = open1 + ' a ' + close1;
      if (open2 && close2) {
        result += ' y ' + open2 + ' a ' + close2;
      }
    }
    return result;
  }

  horario(openHours: string = ''): string[][] {
    if (openHours) {
      const data = (openHours || '').split(';');
      const days = [
        ['Lunes', this._getDayInfo(data, 1)],
        ['Martes', this._getDayInfo(data, 2)],
        ['Miércoles', this._getDayInfo(data, 3)],
        ['Jueves', this._getDayInfo(data, 4)],
        ['Viernes', this._getDayInfo(data, 5)],
        ['Sábado', this._getDayInfo(data, 6)],
        ['Domingo', this._getDayInfo(data, 7)]
      ];
      return days.filter(d => d.length > 0 && d[1] !== '');
    }
    return [];
  }

  selectDepartamento(evt: any) {
    this.zoom = 8;
    this.onChangeXpresDep(evt.target.value);
  }
}

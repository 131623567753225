import isUUID from 'is-uuid';

export default class XpresUtils {
  static isPrivateIP(ip: string) {
    const parts = ip.split('.');
    const re = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (!re.test(ip)) {
      return false;
    }

    return parts[0] === '10' ||
      (parts[0] === '172' && (parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31)) ||
      (parts[0] === '192' && parts[1] === '168');
  }

  static isValidUuid(uuid: string): boolean {
    return isUUID['anyNonNil'](uuid);
  }

  public static generateGUID(): string {
    function S4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }
}

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, share, switchMap } from 'rxjs/operators';
import { IUser, User } from 'src/app/components/user/user.model';
import { environment } from '../../../environments/environment';
import XpresUtils from '../../shared/xpres.util';
import { WINDOW } from './../services/window.provider';

@Injectable()
export class AuthService {

  user: any = null;
  backendUrl: string = null;

  public currentUser = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient,
    @Inject(WINDOW) private window: Window) {
    this.backendUrl = XpresUtils.isPrivateIP(location.host)
      ? environment.privateEndpoint
      : environment.endpoint;
    this.checkUser();
  }

  checkUser(): void {
    if (this.isLoggedIn()) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currentUser.next(this.user);
    }
  }

  translateUser(dbUser: any): IUser {
    if (!dbUser || !dbUser.email) {
      return null;
    }

    const user = new User({
      id: dbUser ? dbUser.id : null,
      firstName: dbUser ? dbUser.firstname : null,
      lastName: dbUser ? dbUser.lastname : null,
      email: dbUser ? dbUser.email : null,
      phoneNumber: dbUser ? dbUser.phone : null,
      street: dbUser ? dbUser.address : null,
      doorNumber: dbUser ? dbUser.doorNumber : null,
      additional: dbUser ? dbUser.additionalAddress : null,
      documentNumber: dbUser ? dbUser.documentNumber : null,
      tipoFiscal: dbUser ? dbUser.tipoFiscal : null,
      isAdmin: dbUser ? dbUser.isAdmin === true : false
    });

    if (dbUser && dbUser.localidad) {
      user.state = {
        id: dbUser.localidad.departamento.id,
        description: dbUser.localidad.departamento.nombre
      };

      user.city = {
        id: dbUser.localidad.id,
        description: dbUser.localidad.nombre
      };
    }

    return user;
  }

  refreshUser(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.request<Observable<HttpResponse<Object>>>('get',
      `${this.backendUrl}/users/current`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json'
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const idToken = res.headers.get('Xpres-Access-Token');
          const expiresIn = res.headers.get('Xpres-Expires-In');

          this.user = this.translateUser(res.body);

          const user = JSON.stringify(this.user);
          this.setSession({ idToken, expiresIn, user });
          this.currentUser.next(this.user);
          return of(this.user);
        }),
        share()
      );
  }

  login(email: string, password: string): Observable<any> {
    const body = { email, password };

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.request<Observable<HttpResponse<Object>>>('post',
      `${this.backendUrl}/login`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const idToken = res.headers.get('Xpres-Access-Token');
          const expiresIn = res.headers.get('Xpres-Expires-In');
          const user = JSON.stringify({});

          this.user = this.translateUser(res.body);
          this.setSession({ idToken, expiresIn, user });
          this.currentUser.next(this.user);
          return of(this.user);
        }),
        share()
      );
  }

  getHostname(): string {
    return this.window.location.protocol + '//' + this.window.location.host;
  }

  register(userData: any): Observable<any> {
    const body = {
      name: userData.name,
      firstname: userData.name,
      lastname: userData.lastName,
      email: userData.email,
      password: userData.password,
      phone: userData.phone,
      documentNumber: userData.rut,
      tipoFiscalId: userData.fiscalType,
      address: userData.address,
      departamentoId: userData.state,
      localidadId: userData.city,
      callbackUrl: `${this.getHostname()}/verify`
    };

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.request<Observable<HttpResponse<Object>>>('post',
      `${this.backendUrl}/register`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          /* const idToken = res.headers.get('Xpres-Access-Token');
          const expiresIn = res.headers.get('Xpres-Expires-In');
          const user = JSON.stringify({});

          this.user = this.translateUser(res.body);
          this.setSession({ idToken, expiresIn, user });
          this.currentUser.next(this.user);
          return of(this.user); */
          return of(null);
        })
      );
  }

  forgot(email: string): Observable<any> {
    const body = {
      email,
      callbackUrl: `${this.getHostname()}/forgot`
     };

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.request<Observable<HttpResponse<Object>>>('post',
      `${this.backendUrl}/forgot`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          return of({});
        })
      );
  }

  verify(verificationId: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.request<Observable<HttpResponse<Object>>>('get',
      `${this.backendUrl}/verify/${verificationId}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json'
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          /* const idToken = res.headers.get('Xpres-Access-Token');
          const expiresIn = res.headers.get('Xpres-Expires-In');

          this.user = this.translateUser(res.body);
          const user = JSON.stringify(this.user);
          this.setSession({ idToken, expiresIn, user });
          this.currentUser.next(this.user);
          return of(this.user); */
          return of(null);
        }),
        share(),
      );
  }

  checkResetToken(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.request<Observable<HttpResponse<Object>>>('get',
      `${this.backendUrl}/checkResetToken/${token}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json'
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          return of(res.body);
        })
      );
  }

  private setSession(session): void {
    const expiresAt = moment().add(session.expiresIn, 'second');

    localStorage.setItem('id_token', session.idToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('user', session.user);
  }

  logout(): void {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user');
    this.currentUser.next(null);
  }

  public isLoggedIn(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  isAuthenticated(): Observable<boolean> {
    return of(this.isLoggedIn());
  }

  observeIsUserLoggedIn(): Observable<boolean> {
    return this.currentUser.asObservable()
      .pipe(
        map(u => u !== null),
        distinctUntilChanged()
      );
  }
  getExpiration(): any {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  getCurrentUserToken(): string {
    return this.isLoggedIn() ? localStorage.getItem('id_token') : null;
  }
}

import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { LoginService } from '../auth/login/login.service';
import { EnvioComponent } from './../envio/envio.component';
import { EnvioService } from './../envio/envio.service';
import { AsistenteService } from './asistente.service';

@Component({
  selector: 'app-asistente-envio',
  templateUrl: './asistente.component.html',
  styleUrls: ['./asistente.component.scss']
})
export class AsistenteComponent implements OnInit, OnDestroy {

  _isMobile = false;
  bsModalRef: BsModalRef;

  @ViewChild('envioTemplate') elementView: ElementRef;
  @ViewChild('envioComponent') private envioComponent: EnvioComponent;

  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  private _unsubscribeAll = new Subject();

  constructor(
    private _auth: AuthService,
    private modalService: BsModalService,
    private modalDataService: ModalDataService,
    private _envioService: EnvioService,
    private router: Router,
    private _responsiveService: ResponsiveService,
    private service: AsistenteService,
    private _login: LoginService
  ) {


  }

  ngOnInit(): void {
    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breackpoint => {
        this._isMobile = breackpoint === 'xs';
      });

    this.service.observeClose()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => this.cerrarAsistente());
    this.service.observeShow()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(envioId => this.show(envioId));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private show(envioId: string = null): void {
    if (this._auth.isLoggedIn()) {
      this.modalDataService.setData(envioId);

      this.bsModalRef = this.modalService.show(this.elementView, {
        class: 'mw-100' + (!this._isMobile ? ' w-75' : ''),
        backdrop: 'static',
        keyboard: false
      });

      // this.bsModalRef.content.onClose.subscribe(result => {
      //   console.log('results', result);
      //   this.close.emit(null);
      // });
    } else {
      this._login.openWithCallbackRoute('/crear-envio');
    }

    this.open.emit(null);
  }

  private cerrarAsistente(): void {
    this.bsModalRef.hide();
    if (this.router.url.indexOf('/home/') >= 0) {
      this.router.navigate(['home'], { queryParams: { ts: new Date().getTime().toString(36) } });
    }
  }
}

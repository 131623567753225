import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor() { }

  goToSection( sectionName: string): void {
    const position = $('#' + sectionName).offset().top;

    $('body, html').animate({
      scrollTop: position
    } /* speed */);
  }

  scrollToTop(): void {
    $('body, html').animate({
      scrollTop: 0
    } /* speed */);
  }
}

import { EnvioService } from './../../envio/envio.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AddressService } from './address.service';
import { IAddress, Address } from './address.model';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import XpresUtils from 'src/app/shared/xpres.util';

declare var $: any;

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit, OnDestroy {

  config: any = {};
  addresses: IAddress[] = [];
  addressForm: FormGroup;

  addrDepartamentos$: Observable<Array<any>>;
  addrLocalidades$: Observable<Array<any>>;

  @Input() userId: string = null;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _addressService: AddressService,
    private _auth: AuthService,
    private _formBuilder: FormBuilder,
    private _envioService: EnvioService
  ) {
    this._unsubscribeAll = new Subject();

    this.addrDepartamentos$ = this._envioService.getDepartamentos();
    this.addrLocalidades$ = this._envioService.getLocalidades('-1');
  }

  ngOnInit(): void {
    this._auth.refreshUser()
      .subscribe(user => {

        this._addressService.getData(user.id)
          .subscribe(data => {
            this.addresses = this._serializeAddresses(data.addresses);
            $('.ps__rail-y').css('z-index', 1000);
          });
      });

    this._buildForms();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  _serializeAddresses(data: any[]): any[] {
    const list = data.map(item => {
      return {
        id: item.id,
        name: item.nombre,
        state: {
          id: item.localidad.departamento.id,
          description: item.localidad.departamento.nombre
        },
        city: {
          id: item.localidad.id,
          description: item.localidad.nombre
        },
        street: item.calle,
        doorNumber: item.doorNumber,
        otherInfo: item.otherInfo,
        phone: item.phone
      };
    });

    list.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });

    return list;
  }

  _getData(): any {
    const form = this.addressForm.controls;

    return {
      id: form['id'].value,
      userId: this.userId,
      name: form['nombre'].value || '',
      street: form['calle'].value || '',
      doorNumber: form['nroPuerta'].value || '',
      otherInfo: form['adicional'].value || '',
      phone: form['telefono'].value || '',
      state: {
        id: form['state'].value
      },
      city: {
        id: form['city'].value
      }
    };
  }

  _buildForms(): void {
    this.addressForm = this._formBuilder.group({
      id: [''],
      userId: [this.userId],
      nombre: ['', Validators.required],
      state: [null, Validators.required],
      city: [null, Validators.required],
      calle: ['', Validators.required],
      nroPuerta: [''],
      adicional: [''],
      telefono: ['']
    });
  }

  formIsInvalid(name: string): boolean {
    if (this.addressForm.controls && this.addressForm.controls[name]) {
      if (this.addressForm.controls[name]['errors'] && !this.addressForm.controls[name]['untouched']) {
        return true;
      }
    }
    return false;
  }

  formHasError(error: string, fieldName: string): boolean {
    if (this.addressForm.controls && this.addressForm.controls[fieldName]) {
      if (this.addressForm.controls[fieldName].hasError(error) && !this.addressForm.controls[fieldName].untouched) {
        return true;
      }
    }
    return false;
  }

  onChangeDeposito(depId: string): void {
    this.addressForm.controls['city'].reset();
    this.addrLocalidades$ = this._envioService.getLocalidades(depId);
  }

  addNew(): void {
    const form = this.addressForm.controls;

    Object.keys(this.addressForm.controls).forEach(key => {
      form[key].reset();
      form[key].markAsUntouched();
      form[key].updateValueAndValidity();
    });

    this._showForm(true);
  }

  save(): void {
    const data = this._getData();

    if (!XpresUtils.isValidUuid(data.id)) {
      this._addressService.addAddress(this.userId, data)
        .subscribe(addresses => {
          this.addresses = this._serializeAddresses(addresses);
          this._hideForm();
        });
    } else {
      this._addressService.updateAddress(this.userId, data)
        .subscribe(addresses => {
          this.addresses = this._serializeAddresses(addresses);
          this._hideForm();
        });
    }
  }

  delete(): void {
    this._addressService.removeAddress(this.userId, this.addressForm.controls['id'].value)
      .subscribe(addresses => {
        this.addresses = this._serializeAddresses(addresses);
        this._hideForm();
      });
  }

  edit(address: any): void {
    const form = this.addressForm.controls;

    Object.keys(this.addressForm.controls).forEach(key => {
      form[key].reset();
      form[key].markAsUntouched();
      form[key].updateValueAndValidity();
    });

    form['id'].setValue(address.id || '');
    form['userId'].setValue(this.userId || '');
    form['nombre'].setValue(address.name || '');
    form['calle'].setValue(address.street || '');
    form['nroPuerta'].setValue(address.doorNumber || '');
    form['adicional'].setValue(address.otherInfo || '');
    form['telefono'].setValue(address.phone || '');

    if (address.state) {
      form['state'].setValue(address.state.id);
      this.addrLocalidades$ = this._envioService.getLocalidades(address.state.id);
      if (address.city) {
        form['city'].setValue(address.city.id);
      }
    }

    this._showForm();
  }

  cancel(): void {
    this._hideForm();
  }

  _showForm(isNew: boolean = false): void {
    $('#section-list').hide(0, function() {
      if (isNew === true) {
        $('#deleteAddress').hide();
      } else {
        $('#deleteAddress').show();
      }
      $('#section-form').fadeIn(400, function () {});
    });
  }

  _hideForm(): void {
    $('#section-form').fadeOut(400, function () {
      $('#deleteAddress').show();
      $('#section-list').show();
    });
  }
}

import { EnvioService } from './../envio/envio.service';
import { Component, ComponentFactoryResolver, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-tracking-history',
  templateUrl: './tracking-history.component.html',
  styleUrls: ['./tracking-history.component.scss']
})
export class TrackingHistoryComponent implements OnInit {

  history: any[] = [];
  loading = false;

  @Input() data: any;

  constructor(
    private el: ElementRef,
    private ren: Renderer2,
    private resolver: ComponentFactoryResolver,
    private _envioService: EnvioService
  ) { }

  ngOnInit() {
    this.history = [];
    this.loading = true;

    setTimeout(() => {
      this._envioService.trackPackage(this.data)
        .subscribe(rows => {
          this.history = rows;
          this.loading = false;
        });
    }, 100);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { skipUntil, skipWhile, take, takeWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Injectable()
export class LoginService {

  public onLoginChanged: BehaviorSubject<string>;

  constructor(private auth: AuthService, private router: Router) {

    // Set the defaults
    this.onLoginChanged = new BehaviorSubject(null);

  }

  open(): void {
    this.onLoginChanged.next('open');
  }

  close(): void {
    this.onLoginChanged.next('close');
  }

  loggedIn(): void {
    this.onLoginChanged.next('loggedin');
  }

  openWithCallbackRoute(r: string) {
    this.open();
    this.auth.observeIsUserLoggedIn()
      .pipe(skipWhile((v: boolean) => !v), take(1))
      .subscribe((loggedIn: boolean) => {
        if (loggedIn) {
          this.router.navigate([r], {skipLocationChange: true});
        } else {
          console.log('failed to navigate to callback');
        }
      });
  }

}

import XpresUtils from 'src/app/shared/xpres.util';

export interface IPickupCenter {
  id: string;
  codigo: number;
  nombre: number;
  calle: string;
  cp?: string;
  telefono?: string;
  nroPuerta?: string;
  adicional?: string;
  horario?: string;
  latitud?: number;
  longitud?: number;
  localidad: {
    id: string,
    codigo: number;
    nombre: string;
    departamento?: {
      id: string,
      codigo: number;
      nombre: string;
    }
  };
}

export class PickupCenter implements IPickupCenter {
  id: string;
  codigo: number;
  nombre: number;
  calle: string;
  cp: string;
  telefono: string;
  nroPuerta: string;
  adicional: string;
  horario: string;
  latitud: number;
  longitud: number;
  localidad: {
    id: string;
    codigo: number;
    nombre: string;
    departamento?: {
      id: string;
      codigo: number;
      nombre: string
    }
  };

  constructor(pickupCenter?) {
    pickupCenter = pickupCenter || {};
    this.id = pickupCenter.id || XpresUtils.generateGUID();
    this.codigo = pickupCenter.codigo ? pickupCenter.codigo : null;
    this.nombre = pickupCenter.nombre || '';
    this.calle = pickupCenter.calle || '';
    this.cp = pickupCenter.cp || '';
    this.telefono = pickupCenter.telefono || '';
    this.nroPuerta = pickupCenter.nroPuerta || '';
    this.adicional = pickupCenter.adicional || '';
    this.horario = pickupCenter.horario || '';
    this.latitud = pickupCenter.latitud ? pickupCenter.latitud : null;
    this.longitud = pickupCenter.longitud ? pickupCenter.longitud : null;

    if (pickupCenter.localidad) {
      this.localidad = {
        id: pickupCenter.localidad.id,
        codigo: pickupCenter.localidad.codigo,
        nombre: pickupCenter.localidad.nombre
      };

      if (pickupCenter.localidad.departamento) {
        this.localidad.departamento = {
          id: pickupCenter.localidad.departamento.id,
          codigo: pickupCenter.localidad.departamento.codigo,
          nombre: pickupCenter.localidad.departamento.nombre
        };
      }
    }
  }
}
